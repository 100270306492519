<template>
	<div class="flexcontainer pt-3 pl-5 pr-5 bg-#f9f9fb">
		<div class="headerDiv mb-2">
			<breadCrumb :routesArray="routesArray"></breadCrumb>
			<div class="row w-full justify-between flex items-center">
				<div class="title flex items-center">
					<h1 :class="titleLength > 40? 'heading-ellipsis':''">{{objectiveMainName}}</h1>
				</div>
				<div class="flex">
					<button type="button" class="btnprimary ml-2 flex items-center" :class="defNotEqual ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="mapControlsToBeforeState()">Cancel</button>
					<button type="button" class="btnprimary ml-2 flex items-center" :class="defNotEqual ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="mapControls()">Save</button>
					<div>
						<button class="ml-2 flex" @click="filterOpen" :class="!columnObject	 ? 'filterButton' : 'btndisabled pointer-events-none'">
							Filters
							<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
						</button>
					</div>
				</div>
			</div>
		</div>
		<div>
			<!-- <div class="w-full pt-5">
				<label class="controllabel">Objective</label>
				<Multiselect
					v-model="selectedObjectives"
					mode="tags"
					:close-on-select="false"
					:classes="{
						container: 'relative h-fit mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-lightgrey rounded bg-white text-base leading-snug outline-none'
					}"
					:options="allObjectives"
					:caret="false"
					:showOptions="false"
					:canClear="false"
					:disabled="true"
					placeholder="Objective"
				>
					<template v-slot:tag="{ option, handleTagRemove, disabled }">
						<div
							class="multiselect-tag"
							:class="{
								'is-disabled': disabled
							}"
						>
							{{ option.label }}
							<span v-if="!disabled" class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
								<span class="multiselect-tag-remove-icon"></span>
							</span>
						</div>
					</template>
				</Multiselect>
			</div>
			<div class="border-b border-solid border-lightgrey my-3"></div> -->
			<div>
				<div class="tableCornerRadius bg-white">
					<div class="table-container">
						<table>
							<thead>
								<th class="header cursor-pointer">
									<div class="flex items-center ml-4">
										<input type="checkbox" class="checkbox cursor-pointer" name="checkbox" disabled />
									</div>
								</th>
								<th scope="col" v-for="column in columnNames" :key="column" class="bg-white cursor-pointer capitalize" @click="sort(column)" :class="getColumnHeadWidth(column)">
									<div>
										<div class="flex pl-5" v-if="column == 'priority'">
											<div>{{ column }}</div>
											<div class="sort cursor-pointer" :class="className == column && !columnObject ? classSort : 'opacity-50'"></div>
										</div>
										<div class="flex" v-else>
											<div>{{ column }}</div>
											<div class="sort cursor-pointer" :class="className == column && !columnObject ? classSort : 'opacity-50'"></div>
										</div>
									</div>
								</th>
								<tr v-if="showFilters">
									<td></td>
									<td v-for="name in columnNames" class="bg-white" :key="name">
										<select class="priorityStyles" :class="filters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-if="name == 'priority'" v-model="filters[name]">
											<option :value="filters.name" selected disabled hidden>Select</option>
											<option class="optionColors" v-for="(value, index) in priorityArray" :key="index" :value="value.id">{{ value.label }}</option>
										</select>
										<input v-else type="text" class="searchForInputBoxStyling" placeholder="Search" v-model="filters[name]" />
									</td>
								</tr>
							</thead>
							<tbody v-if="dataLoading == true">
								<tr class="cursor-pointer empty rowHeight">
									<td class="relative h-5" :colspan="columnNames.size">
										<div class="flex items-center justify-center w-full h-16 skeleton">
											<dots-loader/>
										</div>
									</td>
								</tr>
							</tbody>
							<tbody v-else-if="sortedData.length == 0">
								<tr class="cursor-pointer rowHeight">
									<td class="relative h-10" :colspan="6">
										<div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
									</td>
								</tr>
							</tbody>
							<tbody v-else class="tbodylast">
								<tr v-for="(control, index) in sortedData" :key="control._id" class="rowHeight" >
									<td>
										<div class="flex items-center ml-4">
											<input type="checkbox" name="checkbox" class="checkbox cursor-pointer" v-bind:value="control" v-model="selectedObjects" :checked="checkCheckbox()" />
										</div>
									</td>
									<td v-for="column in columnNames" :key="column" @mouseout="doFalse()" class="cursor-pointer relative" @click="showEditPopUp(control, index)">
										<div class="flex items-center justify-center rounded h-5 w-28" :class="colorPriorityBg(control[column])" v-if="column == 'priority' && typeof control[column] === 'number'">
											<div :class="colorPriorityText(control[column])">
												<div v-if="control[column]">
													{{
														priorityArray.find((l) => {
															return l.id === control[column];
														}).label
													}}
												</div>
												<div v-else>-</div>
											</div>
										</div>
										<div v-else>
											<div class="table-ellipsis">
												<div @mousemove="toolTip(column, $event, index, control, 'text')">
													{{ control[column] }}
												</div>
											</div>
											<div v-if="pop == true && sortedData[tipIndex][column] == tipText && tipColumn == column && tipIndex == index">
												<tool-tip :columnName="column" :tipText="tipText" :styling="styling"></tool-tip>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData" v-if="sortedData.length != 0"></pagination>
				   </div>
				</div>
			</div>
			<popup v-if="mapPopup" :popUp="mapPopup">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2">Objective Controls</h1>
						<button
							class="float-right text-3xl"
							@click="
								onCancelbtn('mapPopup');
								this.mapPopup = false;
							"
						>
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<fieldset>
					<div>
						<div>
							<div class="statuscontainer p-2">
								<div class="formcontainer flex flex-col">
									<div class="row flex flex-row w-full" v-if="selectedControlsForMap.length > 0">
										<div class="p-2 w-full">
											<!-- {{selectedControlsForMap}} -->
											<label for="Controls" class="controllabel">Selected Controls</label>
											<!-- <textarea class="inputboxstyling mt-2 h-32" disabled placeholder="Controls" v-model="controlMapping.controls" /> -->
											<!-- <Multiselect
                                                v-model="selectedControlsForMap"
                                                :options="mappedControlOptions"
                                                :multiple="true"
                                                :disabled="true"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"  
                                                :hide-selected="true" 
                                                :searchable="false"
                                                :hideArrow="false"
                                                class="custom-multiselect"    
                                            >
                                            </Multiselect> -->
											<!-- <Multiselect
												:class="{ multiselect_height: selectedControlsForMap.length != 0 }"
												v-model="selectedControlsForMap"
												:options="mappedControlOptions"
												mode="tags"
												:close-on-select="false"
												:caret="false"
												:showOptions="false"
												:canClear="false"
												:disabled="true"
												placeholder="Controls"
											>
												<template v-slot:tag="{ option, handleTagRemove, disabled }">
													<div
														class="multiselect-tag"
														:class="{
															'is-disabled': disabled
														}"
													>
														{{ option.label }}
														<span v-if="!disabled" class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
															<span class="multiselect-tag-remove-icon"></span>
														</span>
													</div>
												</template>
											</Multiselect> -->
                                             <div class="h-32 mt-2 overflow-y-auto border border-lightgrey p-2 rounded-sm">
												<div class="text-sm flex flex-wrap">
													<div v-for="map in displayMappedControlOptions" :key="map" class="pb-2">
														<div class="w-auto h-6 bg-primary mr-3 px-2 rounded-sm flex items-center text-white text-sm">  {{map.label}} </div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row flex flex-row w-full" v-if="deSelectControlsForMap.length > 0">
										<div class="p-2 w-full">
											<label for="Controls" class="controllabel">Deselected Controls</label>
											<!-- <textarea class="inputboxstyling h-32" disabled placeholder="Controls" v-model="controlMapping.controls" /> -->
											<!-- <Multiselect
                                                v-model="deSelectControlsForMap"
                                                :options="deSelectedControlOptions"
                                                :multiple="true"
                                                :disabled="true"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"  
                                                :hide-selected="true" 
                                                :searchable="false"
                                                :hideArrow="false"
                                                class="custom-multiselect" 
                                                :class="{ multiselect_height: selectedControlsForMap.length != 0 }"   
                                            >
                                            </Multiselect> -->
											<!-- <Multiselect
												:class="{ multiselect_height: selectedControlsForMap.length != 0 }"
												v-model="deSelectControlsForMap"
												:options="deSelectedControlOptions"
												mode="tags"
												:close-on-select="false"
												:caret="false"
												:showOptions="false"
												:canClear="false"
												:disabled="true"
												placeholder="Controls"
											>
												<template v-slot:tag="{ option, handleTagRemove, disabled }">
													<div
														class="multiselect-tag"
														:class="{
															'is-disabled': disabled
														}"
													>
														{{ option.label }}
														<span v-if="!disabled" class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
															<span class="multiselect-tag-remove-icon"></span>
														</span>
													</div>
												</template>
											</Multiselect> -->
											<div class="h-32 mt-2 overflow-y-auto border border-lightgrey p-2 rounded-sm">
												<div class="text-sm flex flex-wrap">
													<div v-for="map in mappedDeSelectedControlOptions" :key="map" class="pb-2">
														<div class="w-auto h-6 bg-primary mr-3 px-2 rounded-sm flex items-center text-white text-sm">  {{map.label}} </div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="buttonposition flex justify-center items-center mb-4 mx-0">
							<!-- <button class="btnprimary opacity-100" @click="onCancelbtn()">Cancel</button> -->
							<button v-if="isLoading && deSelectControlsForMap.length > 0" class="btnprimary opacity-100" @click="saveControls()">Update</button>
							<button v-else class="btnprimary opacity-100" @click="saveControls()">Save</button>
						</div>
					</div>
				</fieldset>
			</popup>
			<popup v-if="popUpControl" :popUp="popUpControl">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2 w-11/12">{{ controlObjectiveObj.controlNumber }}</h1>
						<button
							class="float-right text-3xl"
							@click="
								onCancelbtn('popUpControl');
								this.popUpControl = false;
							"
						>
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<fieldset>
					<div>
						<div>
							<div class="statuscontainer p-2">
								<div class="formcontainer flex flex-col">
									<div class="row flex flex-row w-full">
										<div class="w-6/12 px-2">
											<label class="controllabel">Number</label>
											<div v-if="!controlObjectiveObj.controlNumber" class="text-sm mt-2 mb-3">{{'N/A'}}</div>
											<div class="text-sm mt-2 mb-3" v-else >{{controlObjectiveObj.controlNumber}}</div>
										</div>
										<div class="w-6/12 px-2">
											<label class="controllabel">Title</label>
											<div v-if="!controlObjectiveObj.controlTitle" class="text-sm mt-2 mb-3">{{'N/A'}}</div>
											<div class="text-sm mt-2 mb-3" v-else>{{controlObjectiveObj.controlTitle}}</div>
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-6/12 px-2">
											<!-- <div v-for="(dataValue, indexNum) in controlTypeArray" :key="indexNum">
												<div v-if="controlObjectiveObj.controlType == dataValue.id">
													<label class="controllabel">Type</label>
													<input type="text" class="inputboxstyling mt-2" disabled v-model="dataValue.label" placeholder="Priority" />
												</div>
											</div> -->
											<label class="controllabel">Type</label>
											<div v-if="!controlObjectiveObj.controlType" class="text-sm mt-2 mb-3">{{'N/A'}}</div>
											<div class="text-sm mt-2 mb-3" v-else>{{controlObjectiveObj.controlType}}</div>
										</div>
										<div class="w-6/12 px-2">
											<div v-for="(dataValue, indexNum) in priorityArray" :key="indexNum">
												<div class="flex flex-col mt-1" v-if="controlObjectiveObj.controlPriority == dataValue.id">
													<label class="controllabel">Priority</label>
													<div v-if="dataValue.label == undefined || (dataValue.label != undefined && dataValue.label == null)" class="text-sm mt-2 mb-3">{{'N/A'}}</div>
													<input type="text" class="text-sm mt-2 mb-3" v-else v-model="dataValue.label" />
												</div>
											</div>
											<!-- <label class="controllabel">Priority</label> -->
											<!-- <div class="text-sm mt-2 mb-3">{{controlObjectiveObj.controlPriority}}</div> -->
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-6/12 px-2">
											<label class="controllabel">Family</label>
											<div v-if="!controlObjectiveObj.controlFamily" class="text-sm mt-2 mb-3">{{'N/A'}}</div>
											 <div v-else class="text-sm mt-2 mb-3">{{controlObjectiveObj.controlFamily}}</div>
											<!-- <input type="text" class="inputboxstyling mt-2" placeholder="Family" v-model="controlObjectiveObj.controlFamily" disabled /> -->
										</div>
										<div class="w-6/12 px-2">
											<label class="controllabel">Class</label>
											<div v-if="!controlObjectiveObj.controlClass" class="text-sm mt-2 mb-3">{{'N/A'}}</div>
											 <div v-else class="text-sm mt-2 mb-3">{{controlObjectiveObj.controlClass}}</div>
											<!-- <input type="text" class="inputboxstyling mt-2" placeholder="Class" v-model="controlObjectiveObj.controlClass" disabled /> -->
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-full px-2">
											<label class="controllabel">Audit Methodology</label>
											<div v-if="!controlObjectiveObj.controlAuditMethodology" class="text-sm mt-2 mb-3">{{'N/A'}}</div>
											<div v-else class="text-sm mt-2 mb-3">{{controlObjectiveObj.controlAuditMethodology}}</div>
											<!-- <textarea type="text-area" class="inputboxstyling mt-2 h-24" placeholder="Audit Methodology" v-model="controlObjectiveObj.controlAuditMethodology" disabled> </textarea> -->
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-full px-2">
											<label class="controllabel">Audit Success Criteria</label>
											<div v-if="!controlObjectiveObj.controlGuidance" class="text-sm mt-2 mb-3">{{'N/A'}}</div>
											<div v-else class="text-sm mt-2 mb-3">{{controlObjectiveObj.controlGuidance}}</div>
											<!-- <textarea type="text-area" class="inputboxstyling mt-2 h-24" placeholder="Audit Success Criteria" v-model="controlObjectiveObj.controlGuidance" disabled> </textarea> -->
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-full px-2">
											<label class="controllabel">Maintenance Task</label>
											<div v-if="!controlObjectiveObj.controlMaintenanceTask" class="text-sm mt-2 mb-3">{{'N/A'}}</div>
											<div v-else class="text-sm mt-2 mb-3">{{controlObjectiveObj.controlMaintenanceTask}}</div>
											<!-- <textarea type="text-area" class="inputboxstyling mt-2 h-24" placeholder="Maintenance Task" v-model="controlObjectiveObj.controlMaintenanceTask" disabled> </textarea> -->
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-full px-2">
											<label class="controllabel">Policies</label>
											<div v-if="!controlObjectiveObj.controlPolicies" class="text-sm mt-2 mb-3">{{'N/A'}}</div>
											<div v-else class="text-sm mt-2 mb-3">{{controlObjectiveObj.controlPolicies}}</div>
											<!-- <textarea type="text-area" class="inputboxstyling mt-2 h-24" placeholder="Policies" v-model="controlObjectiveObj.controlPolicies" disabled> </textarea> -->
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-full px-2">
											<label class="controllabel">Guidance</label>
											<div v-if="!controlObjectiveObj.controlGuidance" class="text-sm mt-2 mb-3">{{'N/A'}}</div>
											<div v-else class="text-sm mt-2 mb-3">{{controlObjectiveObj.controlGuidance}}</div>
											<!-- <textarea type="text-area" class="inputboxstyling mt-2 h-24" placeholder="Guidance" v-model="controlObjectiveObj.controlGuidance" disabled> </textarea> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</popup>
		</div>
	</div>
</template>
<style scoped>
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
.tbodylast:last-child {
	border-bottom: 1px solid #e9e9e9;
}
.rowHeight {
	height: 60px;
}
th:first-child,
td:first-child {
	width: 75px;
}
.z-index {
	z-index: 999999;
}
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.priorityStyles{
  width : 112px;
}
::-webkit-scrollbar {
	width: 3px !important;
}
.heading-ellipsis {
  width: 50%;
  display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
// import Multiselect from '@vueform/multiselect';
import Multiselect from 'vue-multiselect';
import { toast } from '@/main';
import useVuelidate from '@vuelidate/core';
import pagination from '@/components/pagination.vue';
import popup from '@/components/popup.vue';
import breadCrumb from '@/components/breadCrumb.vue';
import toolTip from '@/components/toolTip.vue';
import dotsLoader from '@/components/dotsLoader.vue';
export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			visibility: false,
			fullKeys: ['title', 'CCID', 'family', 'class', 'priority'],
			// fullKeys: ['controlTitle', 'controlNumber', 'controlFamily', 'controlClass', 'controlPriority'],
			dummyList: [],
			controlsList: [],
			currentSort: 'name',
			currentSortDir: 'asc',
			classSort: '',
			className: '',
			filters: {},
			mapPopup: false,
			popUpControl: false,
			pop: false,
			showFilters: false,
			titleLength: 0,
			defNotEqual: false,
			controlTypeArray: [
				{ label: 'Preventive', id: 1 },
				{ label: 'Detective', id: 2 },
				{ label: 'Corrective', id: 3 },
				{ label: 'Deterrent', id: 4 },
				{ label: 'Directive', id: 5 },
				{ label: 'Recovery', id: 6 },
				{ label: 'Compensating', id: 7 }
			],
			controlMapping: {
				controls: []
			},
			//Pagination
			styling: { left: '', bottom: '' },
			tipText: '',
			tipColumn: '',
			tipIndex: '',
			pageSize: 20,
			currentPage: 1,
			dynamicPagination: '',
			getObjectivesAll: [],
			selectedControlsForMap: [],
			deSelectControlsForMap: [],
			mappedControlOptions: [],
			dummySelectedObjects : [],
			deSelectedControlOptions: [],
			mappedDeSelectedControlOptions : [],
			departmentsList: [],
			columnObject: false,
			dummyObject: [],
			objectiveList: [],
			objectiveMainName: '',
			dummyDeleteControls: [],
			selectedObjects: [],
			controlRemainObject: [],
			isLoading: false,
			showLoader: false,
			deletedControls: false,
			selectedObjectives: [],
			optionsarray: [],
			routesArray: [],
			orgUsersList: [],
			allControls: [],
			departments: [],
			allObjectives: [],
			controlAllList: [],
			example1: {
				value: 0,
				options: ['Batman', 'Robin', 'Joker']
			},
			displayMappedControlOptions : [],
			colorPriorityText(priority: any) {
				if (priority == 5) {
					return ['text-darkred font-semibold'];
				} else if (priority == 4) {
					return ['text-error font-semibold'];
				} else if (priority == 3) {
					return ['text-yellow font-semibold'];
				} else if (priority == 2) {
					return ['text-green font-semibold'];
				} else if (priority == 1) {
					return ['text-lightgreen font-semibold'];
				}
			},
			colorPriorityBg(priority: any) {
				if (priority == 5) {
					return ['bg-darkredBg border-solid border border-darkred'];
				} else if (priority == 4) {
					return ['bg-errorBg border-solid border border-error'];
				} else if (priority == 3) {
					return ['bg-yellowBg border-solid border border-yellow'];
				} else if (priority == 2) {
					return ['bg-greenBg border-solid border border-green'];
				} else if (priority == 1) {
					return ['bg-lightgreenBg border-solid border border-lightgreen'];
				}
			},
			type: [
				{
					id: 1,
					value: 'Primary'
				},
				{
					id: 2,
					value: 'Secondary'
				}
			],
			priorityArray: [
				{ label: 'Very Low', id: 1 },
				{ label: 'Low', id: 2 },
				{ label: 'Moderate', id: 3 },
				{ label: 'High', id: 4 },
				{ label: 'Critical', id: 5 }
			],
			controlObjectiveObj: {
				controlNumber: '',
				controlTitle: '',
				controlFrameWork: '',
				controlFamily: '',
				controlPriority: '',
				controlClass: '',
				controlType: '',
				controlAuditMethodology: '',
				controlAuditSuccessCriteria: '',
				controlMaintenanceTask: '',
				controlPolicies: '',
				controlGuidance: ''
			},
			dataLoading:true,
		};
	},
	components: {
		toolTip,
		pagination,
		popup,
		Multiselect,
		breadCrumb,
		dotsLoader
	},
	computed: {
		sortedData: function (): any {
			return [...this.controlsList]
				.filter((row: any) => {
					for (const key in this.filters) {
						const inputValue = this.filters[key];
						if (inputValue !== '' || row[key]) {
							// if (Number.isInteger(row[key])) {
							if (Number(inputValue) && isNaN(row[key])) {
								// console.log('Number(inputValue)', Number(inputValue));
								if (!String(row[key]).includes(inputValue)) {
									return false;
								}
							} else {
								// if (!isNaN(row[key])) {
								// 	return false;
								// } else {
								if (!row[key].toString().toLowerCase().includes(inputValue.toString().toLowerCase())) {
									return false;
								}
								// }
							}
						}
					}
					return true;
				})
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					this.dynamicPagination = index + 1;
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		// sortedData: function (): any {
		// 	return [...this.controlsList]
		// 		.filter((row: any) => {
		// 			for (const key in this.filters) {
		// 				if(this.filters[key] == 1){}
		// 				const inputValue = this.filters[key];
		// 				console.log('Filters[key]',inputValue);
						
		// 				if (inputValue !== '' && row[key]) {
		// 					if (Number.isInteger(row[key])) {
		// 						if (row[key] !== Number(inputValue)) {
		// 							return false;
		// 						}
		// 					} else {
		// 						if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
		// 							return false;
		// 						}
		// 					}
		// 				}
		// 			}
		// 			return true;
		// 		})
		// 		.sort((a, b) => {
		// 			let modifier = 1;
		// 			if (this.currentSortDir === 'desc') modifier = -1;
		// 			if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
		// 			if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
		// 			return 0;
		// 		})
		// 		.filter((row, index) => {
		// 			this.dynamicPagination = index + 1;
		// 			let start = (this.currentPage - 1) * this.pageSize;
		// 			let end = this.currentPage * this.pageSize;
		// 			if (index >= start && index < end) return true;
		// 		});
		// },
		columnNames: function (): any {
			let names = new Set();
			for (let row of this.controlsList) {
				for (let key of Object.keys(row)) {
					if (this.fullKeys.includes(key)) {
						names.add(key);
					}
				}
				this.checkEmptyValues();
			}
			return names;
		},
		cantGoBack(): any {
			return this.currentPage === 1;
		},
		cantGoForward(): any {
			return this.controlsList.length / this.pageSize <= this.currentPage;
		}
	},
	async created() {
		this.dataLoading = true;
		let objectiveCrumbId = this.$route.params.objectiveId;
		this.routesArray = [
			{ name: 'Objective', routeName: 'objective' },
			{ name: 'Objective Controls Mapping', routeName: 'objectiveControls', id: `${objectiveCrumbId}` }
		];
		await this.fetchControlsInfo();
		await this.getAllObjectives();
		this.dataLoading = false;
		this.titleLength = this.objectiveMainName ? this.objectiveMainName.length:0
	},
	methods: {
		getColumnHeadWidth(column : any){
           if(column == 'title'){
			return 'w-48'
		   }else if(column == 'family' || column == 'class'){
			return 'w-32'
		   }else if(column == 'CCID'){
			return 'w-40'
		   }else if(column == 'priority'){
			return 'w-36'
		   }
		},
		checkEmptyValues() {
			for (let row of this.controlsList) {
				for (let value of Object.values(row)) {
					if (value === '') {
						this.columnObject = true;
						return;
					}
				}
			}
			this.columnObject = false;
		},
		showEditPopUp(isData: any) {
			this.controlAllList.find((obj: any) => {
				if (obj._id === isData._id) {
					this.popUpControl = true;
					this.controlObjectiveObj = { ...obj };
				}
			});
		},
		onCancelbtn(action: any) {
			this.v$.$reset();
			if (action == 'popUpControl') {
				Object.keys(this.controlObjectiveObj).forEach((prop) => {
					Array.isArray(this.controlObjectiveObj[prop]) ? (this.controlObjectiveObj[prop] = []) : (this.controlObjectiveObj[prop] = '');
				});
			} else if (action == 'mapPopup') {
				Object.keys(this.controlMapping).forEach((prop) => {
					Array.isArray(this.controlMapping[prop]) ? (this.controlMapping[prop] = []) : (this.controlMapping[prop] = '');
				});
			}
		},
		doFalse() {
			this.pop = false;
		},
		toolTip(column: any, e: any, index: any, row: any, status: any) {
			this.tipText = row[column];
			this.tipColumn = column;
			this.tipIndex = index;

			switch (status) {
				case 'text':
					this.handleTextTooltip(e);
					break;
				case 'circleButton':
					if (e.offsetX < e.target.offsetWidth && e.offsetY < e.target.offsetHeight) {
						this.pop = true;
						this.styling.left = e.offsetX + e.target.offsetLeft + 13 + 'px';
						this.styling.bottom = -15 + 'px';
					}
			}
		},
		handleTextTooltip(e: any) {
			const container = e.currentTarget.closest('.table-ellipsis');
			// console.log("tooltip",container)
            // console.log("isTextOverflowed", this.isTextOverflowed(container))
			if (container && this.isTextOverflowed(container)) {
				this.pop = true;
				this.styling.left = e.offsetX + 38 + 'px';
				this.styling.top = 45 + 'px';
			} else {
				this.pop = false;
			}
		},
		isTextOverflowed(element: any) {
            // console.log("element.scrollHeight", element.scrollHeight)
			// console.log("element.clientHeight", element.clientHeight)
			return element.scrollHeight > element.clientHeight;
		},
		controlMethod(control: any) {
			let isControlInArray = false;
			if (control.departments) {
				isControlInArray = control.departments.some((user: any) => {
					return user == this.$route.params.objectiveId;
				});
			}
			if (isControlInArray) {
				return true;
			} else {
				return false;
			}
		},
		mapControlsToBeforeState(){
			this.selectedObjects = this.dummySelectedObjects 
		},
		mapControls() {
			this.mappedControlOptions = [];
			this.displayMappedControlOptions = [];
			this.selectedControlsForMap = [];
			this.deSelectControlsForMap = [];
			this.deSelectedControlOptions = [];
			this.mappedDeSelectedControlOptions = [];
			this.mapPopup = true;
			console.log('dummyDeleteControls',this.dummyDeleteControls);

			for (let i = 0; i < this.selectedObjects.length; i++) {
				let selectedObj = this.selectedObjects[i];
				let foundArr = this.dummyDeleteControls.find((obj: any) => obj._id === selectedObj._id);
				if (!foundArr) {
					this.selectedControlsForMap.push({ value: selectedObj._id, label: selectedObj.CCID });
					this.mappedControlOptions.push({ value: selectedObj._id, label: selectedObj.CCID });
					this.displayMappedControlOptions.push({label: selectedObj.CCID })
					console.log('displayMappedControlOptions',this.displayMappedControlOptions);
					
				}
			}
			
			let uniqueIds = new Set(this.selectedObjects.map((obj: any) => obj._id));

			let selectdDeleteObjects = this.dummyDeleteControls.filter((selectedObjqwer: any) => !uniqueIds.has(selectedObjqwer._id));

			selectdDeleteObjects.forEach((obj: any) => {
				this.deSelectControlsForMap.push({ value: obj._id, label: obj.CCID });
				this.deSelectedControlOptions.push({ value: obj._id, label: obj.CCID });
				this.mappedDeSelectedControlOptions.push({label: obj.CCID})
			});
		},

		paginationUpdations(currentPage: any, pageSize: any) {
			this.currentPage = currentPage;
			this.pageSize = pageSize;
		},
		filterOpen() {
			if (this.columnObject == false) {
				this.showFilters = !this.showFilters;
				if (this.showFilters == true) {
					this.dummyList = this.controlsList;
				} else {
					this.controlsList = this.dummyList;
					this.filters = {};
				}
			}
		},
		checkCheckbox() {
			let defaultInfoArr: Array<any> = [];
			this.dummyObject.map((obj: any) => {
				defaultInfoArr.push(obj);
			});

			let finalInfoArr: Array<any> = [];
			this.selectedObjects.map((obj: any) => {
				finalInfoArr.push(obj);
			});
			if (JSON.stringify(defaultInfoArr) !== JSON.stringify(finalInfoArr)) {
				this.defNotEqual = true;
			} else {
				this.defNotEqual = false;
			}
		},
		async fetchControlsInfo() {
			try {
				console.log('HEYY');
				
				(this.showLoader = true), (this.isLoading = false);
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active`).then((result: { data: any }) => {
					this.isLoading = true;
					this.showLoader = false;
					this.dummyList = result.data;
					this.controlAllList = result.data;
					console.log('0');
				});
			} catch (e) {
				console.log('1');
				this.isLoading = false;
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		filteredFunction(filterColumn: string, columnName: string) {
			this.controlsList = this.dummyList.filter((item: { [x: string]: string }) => {
				for (var property in item) {
					if (property == columnName) {
						return item[property].toLowerCase().includes(filterColumn.toLowerCase());
					}
				}
			});
		},
		sort: function (s: any) {
			this.className = s;
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = s;
			this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
		},
		async getAllObjectives() {
			this.dataLoading = true;
			try {
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/objectives/getall`).then((result: { data: any }) => {
					console.log('12');
					const objectiveId = this.$route.params.objectiveId;
					this.objectiveList = result.data;
					this.selectedObjects = [];
					this.controlRemainObject = [];
					this.objectiveList.forEach((object: any) => {
						if (object._id === objectiveId) {
							console.log('12');
							this.objectiveMainName = object.objective;
							this.controlAllList.forEach((item: any) => {
								if (item.departments && item.departments.includes(object.department)) {
									const isMapped = object.mappedControls && object.mappedControls.includes(item._id);
									console.log('4');
									if (isMapped) {
										let obj = {
											_id: item._id,
											title: item.controlTitle? item.controlTitle :'N/A',
											CCID: item.clientUniqueId? item.clientUniqueId :'N/A',
											family: item.controlFamily? item.controlFamily :'N/A',
											class: item.controlClass? item.controlClass :'N/A',
											priority: item.controlPriority? item.controlPriority :'N/A'
										};
                                        console.log('6', obj);
										this.selectedObjects.push(obj);
										this.dummySelectedObjects = [...this.selectedObjects]
										this.dummyObject = this.selectedObjects;
										this.dummyDeleteControls = [...this.selectedObjects];
										console.log('this.dummyDeleteControls',this.dummyDeleteControls);
										
									} else {
										let obj = {
											_id: item._id,
											title: item.controlTitle ? item.controlTitle :'N/A',
											CCID: item.clientUniqueId ? item.clientUniqueId :'N/A',
											family: item.controlFamily? item.controlFamily :'N/A',
											class: item.controlClass ? item.controlClass :'N/A',
											priority: item.controlPriority ? item.controlPriority :'N/A'
										};
										console.log("qqqqq", obj)
										this.controlRemainObject.push(obj);
									}
								}
							});
						}
					});

					this.controlsList = [...this.selectedObjects, ...this.controlRemainObject];
					this.dataLoading = false;
				});
				if (this.controlsList.length === 0) {
					this.columnObject = true;
					this.controlsList.push({ title: '', CCID: '', family: '', class: '', priority: '' });
					this.sortedData.length = 0; 
				}
				console.log("controlsList11111111111", this.controlsList)
			} catch (e) {
				this.isLoading = false;
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		async saveControls() {
			if (this.deSelectControlsForMap.length > 0) {
				let payload: any = [];
				let controlRouteId = [];
				for (let control of this.deSelectControlsForMap) {
					console.log("deSelectControlsForMap", control)
					// let departmentRouteId = this.$route.params.objectiveId;

					// if (!control.linkedActiveId || !control.departments.some((user: any) => user == departmentRouteId)) {
						controlRouteId.push(control.value);
					// }
					payload = { mappedControls: controlRouteId };
				}
				console.log('payload',payload);
				
				// let payload: any = [];
				// payload = { mappedControls: this.deSelectControlsForMap };
				await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/objective/${this.$route.params.objectiveId}/update?deselect=true`, payload).then(() => {
					this.showLoader = true;
					this.mapPopup = false;
				});
				toast.info('Updated Successfully', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			if (this.selectedControlsForMap.length > 0) {
				let payload: any = [];
				let controlRouteId = [];
				for (let control of this.selectedObjects) {
					let departmentRouteId = this.$route.params.objectiveId;

					if (!control.linkedActiveId || !control.departments.some((user: any) => user == departmentRouteId)) {
						controlRouteId.push(control._id);
					}
					payload = { mappedControls: controlRouteId };
				}
				console.log('payloadsave', payload);
				await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/objective/${this.$route.params.objectiveId}/update`, payload).then(() => {
					this.showLoader = true;
					this.mapPopup = false;
				});
				if(this.isLoading && this.deSelectControlsForMap.length > 0 && this.selectedControlsForMap != undefined){
                   
				}else{
					 toast.info('Saved Successfully', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				}
			}
			this.dummyDeleteControls = [];
			this.selectedControlsForMap = [];
			this.deSelectControlsForMap = [];
			await this.getAllObjectives();
			await this.fetchControlsInfo();
			this.showLoader = false;
		}
	}
});
</script>