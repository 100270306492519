<template>
    <div class="mb-5 mx-5 mt-3">
        <div>
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="row w-full h-full flex mt-2">
            <div class="w-7/12"><h1 class="title" :class="titleLength > 40? 'table-ellipsis':''">{{presentTaskObject.controlTitle}}</h1></div>
            <!-- <div class="flex w-3/5 justify-between">
              <div>
                <p class="text-sm">
                  Id : <b>{{ presentComplianceObject.complianceId }}</b>
                </p>
              </div>
              <div>
                <p class="text-sm">
                  Title : <b>{{ presentComplianceObject.title }}</b>
                </p>
              </div>
            </div> -->
        </div>
        <!-- {{presentTaskObject}} -->
        <div class="w-full minheight">
			<div class="w-full">
				<div class="text-sm">
            <div class="mainContainer tabStructure flex flex-row w-full bg-white border rounded-sm mt-5 px-5 pt-5 pb-3 border-lightgrey">
                <div class="w-75 heightFixing pr-5">
                    <div class="tabStructureDiv">
                        <div class="flex flex-row borderBottom justify-between cursor-pointer tab-container">
                            <div class="pb-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold text-sm primary':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in tabHead" :key="tabIndex" @click="toggleTabs(tab)">
                                <div>
                                    <div class="cursor-pointer">{{ tab.displayName }}</div>
                                    <div :class="{ progress: selectedTabIndex == tab.id }" class="h-1 p-0.75s"></div>
                                </div>
                            </div>
                        </div>
                        <div class="progressbarB rounded h-1"></div>
                    </div>
                    <div>
                      <div class="overflow-y-auto descriptionToggle" v-if="selectedTabObj.id == 1">
                            <div>
                                <div class="border-b border-lightgrey">
                                    <p class="my-1.5 header">Control</p>
                                </div>
                                <div class="flex flex-row w-full pt-5">
                                <div class="controllabel flex items-center border-l border-t border-r w-20 border-b p-2 header border-lightgrey rounded-l-sm">CCID</div>
                                <div class="w-30 border-t border-r border-b p-2 text-xs font-extrabold border-lightgrey">
                                  <!-- <div class="pl-3 flex items-center flex-wrap">
                                  <div :class="[getBorder(), 'text-xs px-2 py-0.5 font-opensans bg-white font-bold h-auto flex items-center']">
                                  {{ presentTaskObject.controlNumber }}
                                </div>
                                </div> -->
                                {{ presentTaskObject.clientUniqueId != undefined ? presentTaskObject.clientUniqueId : 'N/A' }}
                                </div>
                                <div class="controllabel  flex items-center border-t border-r border-b p-2 header border-lightgrey w-23">Frequency</div>
                                <div class="w-27 border-t border-r border-b p-2 header border-lightgrey w-23 rounded-r-sm" v-if="presentTaskObject.controlFrequency">
                                  {{
                                    controlFrequencyArray.find((l) => l.id == presentTaskObject.controlFrequency)?.label
                                  }}
                                </div>
                                <div v-else class="w-27 border-t border-r border-b p-2 header border-lightgrey w-23 rounded-r-sm flex justify-center items-center">
                                 <div>N/A</div>
                                </div>
                            </div>
                            </div>
                            <div>
                                <div class="border-b border-lightgrey">
                                    <p class="my-1.5 header">Details</p>
                                </div>
                                <p v-if="presentTaskObject.controlAuditMethodology == null || presentTaskObject.controlAuditMethodology == undefined" class="mt-1.5 text-xs">{{ 'N/A' }}</p>
                                <p v-else class="mt-1.5 text-xs">{{ presentTaskObject.controlAuditMethodology }}</p>
                            </div>
                          <div v-for="control in controlsArray" :key="control.framework" class="pt-2 pb-3">
                            <div v-for="detail in control.controlsDetailsArray" :key="detail.controlNumber">
                              <div class="border-b border-lightgrey flex flex-row">
                                <p class="text-xs py-0.5 font-opensans bg-white font-bold h-auto flex items-center my-1.5 header">{{ control.framework }}</p>
                              </div>
                              <div class="flex items-center">
                                <div class="flex flex-row w-50 mt-2 mb-2">
                                <div class="w-50 border-t border-l border-r border-b p-2 header border-lightgrey rounded-l-sm">
                                <label class="controllabel">Number</label>
                                </div>
                                 <div class="w-50 border-t border-r border-b p-2 header border-lightgrey rounded-r-sm">
                                  <div class="pl-3 flex items-center flex-wrap">
                                  <div :class="[getBorder(), 'text-xs px-2 py-0.5 font-opensans bg-white font-bold h-auto flex items-center']">
                                  {{ detail.controlNumber }}
                                </div>
                                </div>
                                </div>
                              </div>
                              </div>
                                <div class="border-b border-lightgrey">
                                    <p class="my-1.5 header">Description</p>
                                </div>
                                <p v-if="detail.description == null || detail.description == undefined" class="mt-1.5 text-xs">{{ 'N/A' }}</p>
                                <p v-else class="mt-1.5 text-xs">{{detail.description}}</p>
                                <!-- <label class="controllabel">Description </label>
                                <div class="paddingforDescription paddingrightforDescription">:</div>
                                <div class="text-xs">{{detail.description}}</div> -->
                              </div>
                            </div>
                          <div v-if="kpisObject && kpisObject.length > 0">
                          <div class="border-b border-lightgrey">
                            <p class="my-1.5 header">KPI</p>
                          </div>
                          <div class="flex flex-row w-full pt-2">
                          <div class="border-l border-t border-r w-20 border-b p-2 header border-lightgrey rounded-l-sm">Name</div>
                          <div class="w-30 border-t border-r border-b p-2 header border-lightgrey">{{ kpisObject[0].description != undefined ? kpisObject[0].description : 'N/A' }}</div>
                          <div class="controllabel border-t border-r border-b p-2 header border-lightgrey w-23">Weightage (%)</div>
                          <div class="w-27 border-t border-r border-b p-2 header border-lightgrey w-23 rounded-r-sm"> <div class="pl-20">{{ kpisObject[0].weightage != undefined ? kpisObject[0].weightage : 'N/A' }}</div></div>
                          </div>
                          </div>
                      </div>
 
                        <div class="overflow-y-scroll descriptionToggle"  v-if="selectedTabObj.id == 2">
                            <div>
                                <div class="border-b border-lightgrey">
                                    <p class="my-1.5 header">Documents which are related to the controls</p>
                                </div>
                                <!-- <p class="mt-1.5">{{ descriptionObject._id }}</p> -->
                            </div>
                        </div>
                        <div class="overflow-y-scroll descriptionToggle"  v-if="selectedTabObj.id == 3">
                          <div class="flex justify-end border-b border-lightgrey pb-2"><button class="flex justify-between items-center h-6 w-24" @click="filterOpen" :class="tableRow.length == 0 ? 'btndisabledForFilter pointer-events-none': 'btnprimary flex justify-center taskEditButtons'"> Filters<img src="@/assets/filter.svg" class="ml-2.5" /> </button></div>
                          <tableData :tableRowData="tableRow" :headersData="Headers"></tableData>
                        </div>
                        <div class="overflow-y-scroll descriptionToggle"  v-if="selectedTabObj.id == 4">
                            <div>
                                <!-- <div class="border-b border-lightgrey flex justify-end onepixel">
                                    <div><p class="my-1.5 header">Files uploaded by Auditee/Asignee for Evidence</p></div>
                                    <div class="mb-1.5">
                                        <div class="buttonposition flex justify-center items-center">
                                            <button @click="openFilePicker" :class="TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || TaskDetailsDropDown.taskStatus == 10418 ||  TaskDetailsDropDown.taskStatus == 10401 ? 'btndisabled pointer-events-none paddingForAttachButton': 'btnprimary flex justify-center taskEditButtons'" for="upload" class="w-24">Attach</button>
                                            <input type="file" ref="fileInput" id="upload" @change.stop="handleFileChange" style="display: none" />
                                        </div>
                                    </div>
                                </div> -->
                                <div class="border-b border-lightgrey">
                                    <div class="flex justify-between">
                                        <div class="my-1.5 header">Files</div>
                                        <div><button @click="openFilePicker" :class="TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || TaskDetailsDropDown.taskStatus == 10418 ||  TaskDetailsDropDown.taskStatus == 10401 ? 'btndisabled pointer-events-none paddingForAttachButton': 'btnprimary flex justify-center taskEditButtons'" for="upload" class="w-24">Attach</button></div>
                                </div>
                                </div>
                                <!-- <p class="mt-1.5">Files</p> -->
                                <div v-if="Object.keys(presentTaskObject).includes('files')" class="flex mt-5 gap-4 flex-wrap">
                                    <div v-for="doc in presentTaskObject.files" :key="doc">
                                        <div class="pdf w-full rounded-sm">
                                            <a :href="doc.url" class="flex w-full flex-row items-center">
                                                <img src="@/assets/pdfs.svg" alt="" />
                                                <p v-if="Object.keys(doc).includes('filename')" class="text-xs ml-2">{{doc.filename}}</p>
                                                <p v-else class="text-xs ml-1">{{ doc.name }}</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overflow-y-scroll descriptionToggle"  v-if="selectedTabObj.id == 5">
                            <div>
                                <div class="border-b border-lightgrey flex justify-between">
                                    <div><p class="my-1.5 header">Comments</p></div>
                                    <div>
                                        <div class="buttonposition flex justify-center items-center">
                                            <div class="buttonposition flex justify-center items-center">
                                                <button class="btnprimary w-24 flex taskEditButtons" v-if="oldComments" @click="sortComments()" :class="Object.keys(this.presentTaskObject).includes('comments') && this.presentTaskObject.comments.length != 0 ? 'btnprimary' : 'pointer-events-none'">
                                                    <img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
                                                    Oldest
                                                </button>
                                                <button class="btnprimary w-24 flex taskEditButtons" v-if="!oldComments" @click="sortComments()">
                                                    <img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
                                                    Newest
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="comment-box w-full">
                                        <div class="my-5">
                                            <!-- <textarea placeholder="Add a Comment" class="w-full border border-lightgrey text-sm rounded-sm p-2 text-xs" rows="4" :disabled="TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || TaskDetailsDropDown.taskStatus == 10418" v-model="comment" @input="handleInput"></textarea> -->
                                            <!-- <div ref="editorContainer" class="editor-container"></div> -->
                                            <commentsEditor/>
                                          </div>
                                    </div>
                                    <div v-if="Object.keys(presentTaskObject).includes('comments')" class="comments">
                                        <div v-for="(obj, index) in presentTaskObject.comments" class="border border-lightgrey mt-2 rounded-sm flex flex-row p-2" :key="index">
                                            <div class="w-1/5">
                                                <div class="header">{{ getPersonName(obj.createdBy) }}</div>
                                                <div class="fontSize10PX">{{ obj.createdAt }}</div>
                                            </div>
                                            <!-- <div class="w-4/5 text-xs">{{ obj.comment }}</div> -->
                                            <!-- <div class="w-4/5 text-xs" v-html="obj.comment"></div> -->
                                            <div class="w-4/5 text-xs">
                                              <safeHtml :content="obj.comment"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overflow-y-scroll descriptionToggle"  v-if="selectedTabObj.id == 6">
                            <div>
                                <div class="border-b border-lightgrey">
                                    <div class="flex justify-between">
                                        <div class="my-1.5 header">Findings</div>
                                        <div><button @click="showpopupOnAdd()" :class="(TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || TaskDetailsDropDown.taskStatus == 10418) || presentTaskObject.reviewer != userInfo._id || TaskDetailsDropDown.taskStatus == 10401 ? 'btndisabled pointer-events-none paddingForAttachButton': 'btnprimary flex justify-center taskEditButtons'" class="w-32">Add Findings</button></div>
                                    </div>
                                </div>
                                <div  v-if="Object.keys(presentTaskObject).includes('findings')" class="">
                                    <!-- (this.findingsObject.actualOutput = ''), (this.findingsObject.totalPopulation = ''), (this.findingsObject.finding = ''); -->
                                  <div v-for="(obj, index) in findingsForCompliance" :key="index" class="w-full h-auto mt-5">
                                      <!-- <div class="row flex flex-row w-full">
                                          <div class="w-6/12">
                                              <div class="pb-2">
                                                  <label class="controllabel">Total Population</label>
                                                  <p>{{ obj.totalPopulation }}</p>
                                              </div>
                                              <div class="pb-2">
                                                  <label class="controllabel">Actual Output</label>
                                                  <p>{{ obj.actualOutput }}</p>
                                              </div>
                                          </div>
                                          <div class="w-6/12 flex items-center">
                                              <div class="pb-2">
                                                  <label class="controllabel">Performance Output</label>
                                                  <p>{{ obj.actualOutput }}</p>
                                              </div>
                                          </div>
                                      </div>
                                      <p class="mr-2 pb-2 pt-2">Comments : {{ obj.comments }}</p> -->
                                      <!-- <p class="mr-2 pt-2">Evidence - {{index+1}} : {{ obj.findingEvidence }}</p> -->

                                      <div class="flex flex-row">
                                        <div class="border-t border-l border-b p-2 header w-23 border-lightgrey rounded-tl-md">Finding ID</div>
                                        <div  class="border header border-lightgrey w-77 p-2 rounded-tr-md text-xs">
                                            {{obj.findingId}}
                                        </div>
                                      </div>
                                      <div class="flex flex-row">
                                          <div class="border-l border-b p-2 header w-23 border-lightgrey">Finding</div> 
                                          <div  class="border-r border-b border-l border-lightgrey w-77 p-2 text-xs">{{ obj.finding }}</div>
                                      </div>
                                      <div class="flex flex-row">
                                          <div class="border-l border-b p-2 header w-23 border-lightgrey rounded-bl-md">Evidence</div> 
                                          <div  class="border-r border-b border-l border-lightgrey w-77 p-2 rounded-br-md text-xs">{{ obj.findingEvidence }}</div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div class="overflow-y-scroll descriptionToggle"  v-if="selectedTabObj.id == 7">
                          <!-- {{conditionForResultBtn()}}
                          {{!presentTaskObject.kpis && (Object.keys(presentTaskObject).includes('controlResults') && presentTaskObject.controlResults.length != 0)}}
                          {{presentTaskObject.reviewer != userInfo._id}} -->
                          <!-- {{Object.keys(presentTaskObject).includes('results') && presentTaskObject.results.length}} -->
                          <!-- {{presentTaskObject.results.length}}
                          {{presentTaskObject.kpis.length}} -->
                          <!-- {{resultsForCompliance}} === {{presentTaskObject.kpis}} -->
                          <!-- {{kpisArray.length}} -->
                          <!-- {{ Object.keys(presentTaskObject).includes('results') && Object.keys(presentTaskObject).includes('kpis') && presentTaskObject.results.length != presentTaskObject.kpis.length }}
                          {{controlResultsForCompliance.length}}
                          {{Object.keys(presentTaskObject).includes('controlResults') && presentTaskObject.controlResults.length}} -->

                          <!-- {{resultsForCompliance.length}} -->
                          <!-- {{(resultsForCompliance.length == presentTaskObject.kpis.length)}} ---
                          {{resultsForCompliance.length >= presentTaskObject.kpis.length  && controlResultsForCompliance.length == 1}} -----\
                          {{(controlResultsForCompliance.length >=1 && presentTaskObject.kpis == null)}} -->
                            <div>
                                <div class="border-b border-lightgrey">
                                    <div class="flex justify-between">
                                        <div class="my-1.5 header">Control Result</div>
                                        <div><button @click="showpopupOnAddForResult()" :class="conditionForResultBtn() || (!presentTaskObject.kpis && (Object.keys(presentTaskObject).includes('controlResults') && presentTaskObject.controlResults.length != 0) || presentTaskObject.reviewer != userInfo._id) ? 'btndisabled pointer-events-none paddingForAttachButton': 'btnprimary flex justify-center taskEditButtons'" class="w-32">Add Result</button></div>
                                    </div>
                                </div>
                                  <!-- contorlResults -->
                                  <div>
                                    
                                      <div v-if="controlResultsForCompliance.length != 0">
                                        <div v-for="(obj, index) in controlResultsForCompliance" :key="index">
                                          <div class="flex flex-row mt-5" v-if="obj.resultfor == 1">
                                            <div class="border-t border-l border-b p-2 header w-23 border-lightgrey rounded-tl-md">Performance Analysis</div> 
                                            <div  class="border header border-lightgrey w-77 p-2 rounded-tr-md">
                                              <div class="ml-2 mr-2">
                                                <div class="progress-container">
                                                  <!-- <div class="progress-bar100" v-if="obj.controlProgress >= 50" :style="{ '--progress100': obj.controlProgress + '%' }">
                                                    </div> -->
                                                    <div class="progress-bar">
                                                    </div>
                                                  </div>
                                                  <div class="progress-indicator-container">
                                                    <div class="indicatorIcon100" v-if="obj.controlProgress >= 50" :style="{ '--progress100': obj.controlProgress + '%' }">
                                                    </div>
                                                    <div class="indicatorIcon" v-else :style="{ '--progress': obj.controlProgress + '%' }">
                                                    </div>
                                                  </div>
                                                  <div class="progress-indicator-count">
                                                    <p class="indicator" v-if="obj.controlProgress >= 50" :style="{left: `calc(${obj.controlProgress}% - 20px)`}">{{ obj.controlProgress }}%</p>
                                                    <p class="indicator" v-else :style="{left: `calc(${obj.controlProgress}%)`}">{{ obj.controlProgress }}%</p>
                                                  </div>
                                                  </div>
                                                </div>
                                            </div>
                                          <div class="flex flex-row">
                                              <div class="border-l border-b p-2 header w-23 border-lightgrey">Risk Severity</div> 
                                              <div class="border-b border-l border-r border-lightgrey w-77 p-2">
                                              <!-- <div class="border-r border-b border-l border-lightgrey w-77 p-2"> -->
                                              <div class="flex items-center justify-center rounded h-5 w-28 px-2.5 regular" :class="colorRiskBg(obj.severity)">
                                                <div :class="colorRiskText(obj.severity)">
                                                  {{getSeverityLabel(obj.severity)}}
                                                </div>
                                              </div>
                                              </div>
                                              
                                          </div>
                                          <div class="flex flex-row">
                                                <div class="border-l border-b p-2 header w-23 border-lightgrey">Compliant </div> 
                                                <!-- <div class="border-l border-b p-2 header w-23 border-lightgrey">Compliant (A)</div>  -->
                                                <div class="border-b border-l border-lightgrey text-xs flex items-center w-27 p-2">{{ obj.actualOutput }}</div>
                                          <!-- </div>
                                          <div class="flex flex-row"> -->
                                              <div class="border-l border-b p-2 header w-23 border-lightgrey">Population </div> 
                                              <div  class="border-r border-b border-l text-xs flex items-center border-lightgrey w-27 p-2">{{ obj.totalPopulation }}</div>
                                              <!-- <div class="border-l border-b p-2 header w-23 border-lightgrey">Population (B)</div>  -->
                                              <!-- <div  class="border-r border-b border-l border-lightgrey w-27 p-2">{{ obj.totalPopulation }}</div> -->
                                          </div>
                                          <div class="flex flex-row">
                                              <div class="border-l border-b p-2 header w-23 border-lightgrey rounded-bl-md">Comments</div> 
                                              <div  class="border-r border-b border-l border-lightgrey w-77 p-2 text-xs flex items-center rounded-br-md">{{ obj.comments }}</div>
                                          </div>
                                          </div>
                                        </div>
                                        <div v-else-if="controlResultsForCompliance.length <= 0">
                                          <div>
                                          <div class="flex flex-row mt-5">
                                            <div class="border-t border-l border-b p-2 header w-23 border-lightgrey rounded-tl-md">Performance Analysis</div> 
                                            <div  class="border header border-lightgrey w-77 p-2 rounded-tr-md">
                                              <!-- <div class="ml-2 mr-2">
                                                <div class="progress-container">
                                                  <div class="progress-bar100" v-if="controlProgress >= 50" :style="{ '--progress100': controlProgress + '%' }">
                                                  </div>
                                                  <div class="progress-bar" v-else :style="{ '--progress': controlProgress + '%' }">
                                                  </div>
                                                </div>
                                                <div>
                                                  <div class="progress-indicator-container">
                                                    <span class="indicator" v-if="controlProgress >= 50" :style="{left: `calc(${controlProgress}% - 20px)`}">{{ controlProgress }}%</span>
                                                    <span class="indicator" v-else :style="{left: `calc(${controlProgress}%)`}">{{ controlProgress }}%</span>
                                                  </div>
                                                </div>
                                              </div> -->
                                              <div class="ml-2 mr-2">
                                                  <div class="progress-container">
                                                    <!-- <div class="progress-bar100" v-if="controlProgress >= 50" :style="{ '--progress100': controlProgress + '%' }">
                                                    </div> -->
                                                    <div class="progress-bar">
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <!-- <div class="progress-bar100" v-if="controlProgress >= 50" :style="{ '--progress100': controlProgress + '%' }">
                                                    </div>
                                                    <div class="progress-bar" v-else :style="{ '--progress': controlProgress + '%' }">
                                                    </div> -->
                                                    <div class="progress-indicator-container w-full">
                                                      <!-- <div class="indicatorIcon100" v-if="controlProgress >= 50" :style="{ '--progress100': controlProgress + '%' }">
                                                      </div> -->
                                                      <div class="indicatorIcon" :style="{ '--progress': 0 + '%' }">
                                                      </div>
                                                    </div>
                                                    <div class="progress-indicator-count">
                                                      <!-- <span class="indicator" v-if="controlProgress >= 50" :style="{left: `calc(${controlProgress}% - 20px)`}">{{ controlProgress }}%</span> -->
                                                      <p class="indicator" :style="{left: `calc(${0}%)`}">{{ 0 }}%</p>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                          <div class="flex flex-row">
                                              <div class="border-l border-b p-2 header w-23 border-lightgrey">Risk Severity</div> 
                                              <div class="border-b border-l  border-r border-lightgrey w-77 p-2">
                                              
                                              </div>
                                              
                                          </div>
                                          <div class="flex flex-row">
                                                <div class="border-l border-b p-2 header w-23 border-lightgrey">Compliant </div> 
                                                <div class="border-b border-l border-lightgrey w-27 p-2"></div>
                                          <!-- </div>
                                          <div class="flex flex-row"> -->
                                              <div class="border-l border-b p-2 header w-23 border-lightgrey">Population </div> 
                                              <div  class="border-r border-b border-l border-lightgrey w-27 p-2"></div>
                                          </div>
                                          <div class="flex flex-row">
                                              <div class="border-l border-b p-2 header w-23 border-lightgrey rounded-bl-md">Comments</div> 
                                              <div  class="border-r border-b border-l border-lightgrey w-77 p-2 rounded-br-md"></div>
                                          </div>
                                          </div>
                                          </div>
                                    </div>
                                        <!-- Kpiresults -->

                                        <div class="border-b mt-3 border-lightgrey" v-if="presentTaskObject.kpis">
                                          <div class="flex justify-between">
                                              <div class="my-1.5 header">KPI Result</div>
                                          </div>
                                        </div>
                                      <div v-if="resultsForCompliance.length != 0">
                                        <div v-for="(obj, index) in presentTaskObject.results" :key="index">
                                          <!-- <div v-for="i in presentTaskObject.kpis" :key="i._id"> -->

                                          
                                            <div class="flex flex-row mt-5">
                                              <div class="border-t border-l border-b p-2 header w-23 border-lightgrey rounded-tl-md">Performance Analysis</div> 
                                              <div  class="border header border-lightgrey w-77 p-2 rounded-tr-md">
                                                <div class="ml-2 mr-2">
                                                  <div class="progress-container">
                                                    <!-- <div class="progress-bar100" v-if="obj.kpiProgress >= 50" :style="{ '--progress100': obj.kpiProgress + '%' }">
                                                    </div> -->
                                                    <div class="progress-bar">
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div class="progress-indicator-container w-full">
                                                      <div class="indicatorIcon100" v-if="obj.kpiProgress >= 50" :style="{ '--progress100': obj.kpiProgress + '%' }">
                                                      </div>
                                                      <div class="indicatorIcon" v-else :style="{ '--progress': obj.kpiProgress + '%' }">
                                                      </div>
                                                    </div>
                                                    <div class="progress-indicator-count">
                                                      <p class="indicator" v-if="obj.kpiProgress >= 50" :style="{left: `calc(${obj.kpiProgress}% - 20px)`}">{{ obj.kpiProgress }}%</p>
                                                      <p class="indicator" v-else :style="{left: `calc(${obj.kpiProgress}%)`}">{{ obj.kpiProgress }}%</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="flex flex-row">
                                                <div class="border-l border-b p-2 header w-23 border-lightgrey">Risk Severity</div> 
                                                <div class="border-b border-l border-lightgrey w-27 p-2">
                                                <div class="flex items-center justify-center rounded h-5 w-28 px-2.5 regular" :class="colorRiskBg(obj.severity)">
                                                  <div :class="colorRiskText(obj.severity)">
                                                    {{getSeverityLabel(obj.severity)}}
                                                  </div>
                                                </div>
                                                </div>
                                                <!-- <div class="flex"> -->
                                                  <div v-if="obj.controlorKpi != ''" class="border-l border-b p-2 header w-23 border-lightgrey">KPI Number</div> 
                                                  <div v-if="obj.controlorKpi != ''" class="border-r border-b border-l border-lightgrey w-27 p-2">{{ presentTaskObject.kpis.find((kpi)=>{return kpi._id == obj.controlorKpi}).description }}</div>
                                                <!-- </div> -->
                                            </div>
                                            <div class="flex flex-row">
                                                  <div class="border-l border-b p-2 header w-23 border-lightgrey">Compliant </div> 
                                                  <div class="border-b border-l border-lightgrey w-27 p-2">{{ obj.actualOutput }}</div>
                                            <!-- </div>
                                            <div class="flex flex-row"> -->
                                                <div class="border-l border-b p-2 header w-23 border-lightgrey">Population </div> 
                                              <div  class="border-r border-b border-l border-lightgrey w-27 p-2">{{ obj.totalPopulation }}</div>
                                            </div>
                                            <div class="flex flex-row">
                                              <div class="border-l border-b p-2 header w-23 border-lightgrey rounded-bl-md">Comments</div> 
                                              <div  class="border-r border-b border-l border-lightgrey w-77 p-2 rounded-br-md">{{ obj.comments }}</div>
                                            </div>
                                        <!-- </div> -->
                                        </div>
                                      </div>
                                  <div v-else-if="resultsForCompliance.length == 0">
                                    <!-- <div v-for="i in kpisArray"> gffggsf -->
                                      <div v-for="i in presentTaskObject.kpis" :key="i">
                                        <div v-if="(presentTaskObject.kpis != null || presentTaskObject.kpis != undefined) && (presentTaskObject.kpis.length > 0)">
                                          <div class="flex flex-row mt-5">
                                            <div class="border-t border-l border-b p-2 header w-23 border-lightgrey rounded-tl-md">Performance Analysis</div> 
                                            <div  class="border header border-lightgrey w-77 p-2 rounded-tr-md">
                                              <div class="ml-2 mr-2">
                                                <div class="progress-container">
                                                  <!-- <div class="progress-bar100" v-if="kpiProgress >= 50" :style="{ '--progress100': 0 + '%' }">
                                                  </div> -->
                                                  <div class="progress-bar">
                                                  </div>
                                                </div>
                                                <div>
                                                  <div class="progress-indicator-container w-full">
                                                    <div class="indicatorIcon" :style="{ '--progress': 0 + '%' }">
                                                    </div>
                                                  </div>
                                                  <div class="progress-indicator-count">
                                                    <p class="indicator" :style="{left: `calc(${0}%)`}">{{ 0 }}%</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="flex flex-row">
                                              <div class="border-l border-b p-2 header w-23 border-lightgrey">Risk Severity</div> 
                                              <div class="border-b border-l border-r border-lightgrey w-77 p-2">
                                              
                                              </div>
                                              <!-- <div class="flex"> -->
                                                <div class="border-l border-b p-2 header w-23 border-lightgrey">KPI Number</div> 
                                                <div class="border-r border-b border-l border-lightgrey w-27 p-2"></div>
                                              <!-- </div> -->
                                          </div>
                                          <div class="flex flex-row">
                                            <div class="border-l border-b p-2 header w-23 border-lightgrey">Compliant </div> 
                                            <div class="border-b border-l border-lightgrey w-27 p-2"></div>
                                          <!-- </div>
                                          <div class="flex flex-row"> -->
                                            <div class="border-l border-b p-2 header w-23 border-lightgrey">Population </div> 
                                            <div  class="border-r border-b border-l border-lightgrey w-27 p-2"></div>
                                          </div>
                                          <div class="flex flex-row">
                                            <div class="border-l border-b p-2 header w-23 border-lightgrey rounded-bl-md">Comments</div> 
                                            <div  class="border-r border-b border-l border-lightgrey w-77 p-2 rounded-br-md"></div>
                                          </div>
                                        </div> 
                                      </div>
                                  <!-- </div> -->
                                </div>

                                <div v-if="presentTaskObject.results">
                                  <div v-if="presentTaskObject.kpis.length != presentTaskObject.results.length">
                                    <div v-for="index in (presentTaskObject.kpis.length - presentTaskObject.results.length)" :key="index">
                                    <div class="flex flex-row mt-5">
                                      <div class="border-t border-l border-b p-2 header w-23 border-lightgrey rounded-tl-md">Performance Analysis</div> 
                                      <div class="border header border-lightgrey w-77 p-2 rounded-tr-md">
                                        <div class="ml-2 mr-2">
                                          <div class="progress-container">
                                            <!-- <div class="progress-bar100" v-if="kpiProgress >= 50" :style="{ '--progress100': 0 + '%' }">
                                            </div> -->
                                            <div class="progress-bar">
                                            </div>
                                          </div>
                                          <div>
                                            <div class="progress-indicator-container w-full">
                                              <div class="indicatorIcon" :style="{ '--progress': 0 + '%' }">
                                              </div>
                                            </div>
                                            <div class="progress-indicator-count">
                                              <p class="indicator" :style="{left: `calc(${0}%)`}">{{ 0 }}%</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                      <div class="flex flex-row">
                                          <div class="border-l border-b p-2 header w-23 border-lightgrey">Risk Severity</div> 
                                          <div class="border-b border-l border-r border-lightgrey w-77 p-2">
                                          
                                          </div>
                                          <!-- <div class="flex"> -->
                                            <div class="border-l border-b p-2 header w-23 border-lightgrey">KPI Number</div> 
                                            <div class="border-r border-b border-l border-lightgrey w-27 p-2"></div>
                                          <!-- </div> -->
                                      </div>
                                      <div class="flex flex-row">
                                        <div class="border-l border-b p-2 header w-23 border-lightgrey">Compliant </div> 
                                        <div class="border-b border-l border-lightgrey w-27 p-2"></div>
                                      <!-- </div>
                                      <div class="flex flex-row"> -->
                                        <div class="border-l border-b p-2 header w-23 border-lightgrey">Population </div> 
                                        <div  class="border-r border-b border-l border-lightgrey w-27 p-2"></div>
                                      </div>
                                      <div class="flex flex-row">
                                        <div class="border-l border-b p-2 header w-23 border-lightgrey rounded-bl-md">Comments</div> 
                                        <div  class="border-r border-b border-l border-lightgrey w-77 p-2 rounded-br-md"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            <!-- </div> -->
                            <!-- </div> -->
                                <!-- </div> -->
                                <!-- </div> -->
                                <!-- </div> -->
                            <!-- </div> -->

                          </div>
                        </div>
                        <div class="overflow-y-scroll descriptionToggle"  v-if="selectedTabObj.id == 8">
                            <div>
                                <div class="border-b border-lightgrey flex justify-between">
                                    <div><p class="my-1.5 header">Work History</p></div>
                                    <div>
                                        <div class="buttonposition flex justify-center items-center">
                                            <div class="buttonposition flex justify-center items-center">
                                                <button class="btnprimary w-24 flex taskEditButtons" v-if="newHistory" @click="sortWorkLog()">
                                                    <img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
                                                    Oldest
                                                </button>
                                                <button class="btnprimary w-24 flex taskEditButtons" v-if="!newHistory" @click="sortWorkLog()">
                                                    <img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
                                                    Newest
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-for="work in workLogObject.taskHistory" :key="work" class="bg-white p-4 border-lightgrey border-b w-full">
                                    <div class="flex ml-2">
                                        <div v-if="Object.keys(work).includes('createdBy')" class="initials cursor-pointer">
                                            {{ extractInitials(getPersonName(work.createdBy)) }}
                                        </div>
                                        <div v-if="Object.keys(work).includes('updatedBy')" class="initials cursor-pointer">
                                            {{ extractInitials(getPersonName(work.updatedBy)) }}
                                        </div>
                                        <div class="logContainer w-93">
                                            <div class="flex pt-1.5">
                                                <p v-if="Object.keys(work).includes('createdBy')" class="font-semibold cursor-pointer text-xs">
                                                    {{ getPersonName(work.createdBy) }}
                                                </p>
                                                <p v-else-if="Object.keys(work).includes('updatedBy')" class="font-semibold cursor-pointer text-xs">
                                                    {{ getPersonName(work.updatedBy) }}
                                                </p>
                                                <p v-if="work.actionEntity == 10301" class="mx-2 text-xs">{{ 'Created the Task on' }}</p>
                                                <p v-else-if="work.actionEntity == 10302" class="mx-2 text-xs">{{ 'Updated the Task on' }}</p>
                                                <p v-if="Object.keys(work).includes('createdAt')" class="font-semibold cursor-pointer text-xs">{{ formatTime(work.createdAt) }}</p>
                                                <p v-if="Object.keys(work).includes('updatedAt')" class="font-semibold cursor-pointer text-xs">{{ formatTime(work.updatedAt) }}</p>
                                            </div>
                                            <div class="mt-4" v-if="Object.keys(work).includes('additionalInfo')">
                                                <div class="flex" v-if="Object.keys(work.additionalInfo).includes('comment')">
                                                    <p class="font-semibold text-xs w-12">{{ 'Comment:' }}</p>



                                                    <!-- <p class="text-xs w-88 ml-4">{{ work.additionalInfo.comment }}</p> -->

                                                    <div class="text-xs w-88 ml-4">
                                                      <safeHtml :content="work.additionalInfo.comment"/>
                                                    </div>





                                                </div>
                                                <div class="flex" v-if="Object.keys(work.additionalInfo).includes('actualOutput')">
                                                  <p class="font-semibold cursor-pointer text-xs pr-2">{{'Added the Result -'}}</p>
                                                    <p class="font-semibold cursor-pointer text-xs">{{ 'Compliant (A) :' }}</p>
                                                    <p class="mx-2 text-xs">{{ work.additionalInfo.actualOutput }}</p>
                                                    <p class="font-semibold cursor-pointer text-xs">{{ 'Population (B) :' }}</p>
                                                    <p class="mx-2 text-xs">{{ work.additionalInfo.totalPopulation }}</p>
                                                </div>
                                                <div class="flex" v-if="Object.keys(work.additionalInfo).includes('findingId')">
                                                    <p class="font-semibold cursor-pointer text-xs">{{'Added the finding -'}}</p>
                                                    <p class="mx-2 text-xs">{{ work.additionalInfo.findingId }}</p>
                                                </div>
                                                <div class="flex" v-if="Object.keys(work.additionalInfo).includes('evidence')">
                                                    <p class="font-semibold cursor-pointer text-xs">{{'Uploaded the Evidence -'}}</p>
                                                    <p class="mx-2 text-xs">{{ work.additionalInfo.evidence }}</p>
                                                </div>
                                                <div class="flex" v-if="Object.keys(work.additionalInfo).includes('assignee')">
                                                    <p class="font-semibold cursor-pointer text-xs">{{'Assigned the task to -'}}</p>
                                                    <p class="mx-2 text-xs">{{ getPersonName(work.additionalInfo.assignee) }}</p>
                                                </div>
                                                <div class="flex items-center" v-if="Object.keys(work.additionalInfo).includes('previousStatus')">
                                                    <div>
                                                        <p class="font-semibold cursor-pointer text-xs">{{'Updated The Status :'}}</p>
                                                    </div>
                                                    <div class="flex ml-4">
                                                        <div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorComplianceBg(work.additionalInfo.previousStatus)">
                                                            <div :class="colorComplianceText(work.additionalInfo.previousStatus)">
                                                                {{ getStatusLabel(work.additionalInfo.previousStatus) }}
                                                            </div>
                                                        </div>
                                                        <div class="mx-4 flex items-center"><img src="@/assets/arrowRight.svg"/></div>
                                                        <div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorComplianceBg(work.additionalInfo.updatedStatus)">
                                                            <div :class="colorComplianceText(work.additionalInfo.updatedStatus)">
                                                                {{ getStatusLabel(work.additionalInfo.updatedStatus) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-25 border taskDetailsRightBar rounded-sm border-lightgrey">
                    <div class="m-3">
                        <div class="pb-2 border-b border-lightgrey"><h1>Task Details</h1></div>
                        <div class="pt-2 overflow-y-scroll thirtyWidthToggle">
                            <div>
                                <label class="controllabel">Task Status</label>
                                 <div v-if="TaskDetailsDropDown.taskStatus == 10418" class="bg-error textColorWhite inputboxstyling1 cursor-pointer mt-2 mb-3">
                                     <div class="bg-error textColorWhite">{{'Terminated'}}</div>
                                  </div>
                                  <div v-else>
                                      <select class="custom-select inputboxstyling1 cursor-pointer mt-2" :disabled="TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417" v-model="TaskDetailsDropDown.taskStatus" :class="changeBackground()" @change="updateTaskStatus(TaskDetailsDropDown.taskStatus)">
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option v-for="i in filteredTaskStatusData" :value="i.id" :key="i" :disabled="i.id == 10401" :class="i.id == 10401 ? 'taskStatusOptionsForNotStarted' : 'taskStatusOptions'">
                                            {{ i.status }}
                                        </option>
                                    </select>
                                  </div>
                            </div>
                            <div class="mt-3">
                               <!-- || presentTaskObject.teamOwner != userInfo._id -->
                                <label class="controllabel">Assigned To</label>
                                <div v-if="newTeamLeader.value != userInfo._id  && presentTaskObject.teamOwner != userInfo._id || TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || TaskDetailsDropDown.taskStatus == 10418">
                                  <p class="mt-2">{{ newTeamLeader.label }}</p>
                                </div>
                                <div v-else>
                                  <Multiselect
                                    v-model="presentComplianceObject.teamLeader"
                                    :options="TeamOwnerOptions"
                                    @search-change="asyncFind1"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="true"
                                    @select="updateTaskForTeamLeader(presentComplianceObject.teamLeader)"
                                    label="label"
                                    track-by="value"    
                                    :searchable="true"
                                    :hideArrow="false"
                                    placeholder="TeamLeader"
                                    open-direction="bottom"
                                    deselectLabel=""
                                    selectLabel=""
                                    class="custom-multiselect userSelect mt-2 mb-3"
                                >
                                </Multiselect>
                                </div>
                            </div>
                            <div class="mt-3">
                                <label class="controllabel">Reviewer</label><br>
                                <p v-if="presentTaskObject.reviewer != undefined || presentTaskObject.reviewer != null"  class="mt-2 text-sm">{{ getPersonName(presentTaskObject.reviewer) }}</p>
                                <p class="mt-2 text-sm" v-else>N/A</p>
                                <!-- <input class="inputboxstyling cursor-pointer" type="text" placeholder="Name of the Reviewer" /> -->
                            </div>
                            <div class="mt-3">
                                <label class="controllabel">Team Owner</label><br>
                                <p v-if="presentTaskObject.teamOwner != undefined || presentTaskObject.teamOwner != null"  class="mt-2 text-sm">{{ getPersonName(presentTaskObject.teamOwner) }}</p>
                                <p class="mt-2 text-sm" v-else>N/A</p>
                                <!-- <input class="inputboxstyling cursor-pointer" type="text" placeholder="Default will be Team Owner" /> -->
                            </div>
                            <div class="mt-3">
                                <label class="controllabel">Team</label><br>
                                <p v-if="presentTaskObject.department != undefined || presentTaskObject.department != null" class="mt-2 text-sm">{{ getTeamName(presentTaskObject.department) }}</p>
                                <p class="mt-2 text-sm" v-else>N/A</p>
                                    <!-- {{ getPersonName(presentTaskObject.department) }} -->
                            </div>
                            <div class="mt-3">
                                <label class="controllabel">Entity</label><br>
                                <p v-if="presentTaskObject.entity != undefined || presentTaskObject.entity != null" class="mt-2 text-sm">{{ getLocationName(presentTaskObject.entity,presentTaskObject.entitytypecodeid) }}</p>
                                <p class="mt-2 text-sm" v-else>N/A</p>
                                <!-- <div class="inputboxstyling">{{ getLocation(presentTaskObject.department) }}</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <popup v-if="showPopUp" :popUp="showPopUp">
          <template v-slot:header>
              <div class="flex items-center justify-between m-4 rounded">
                  <h1>Findings</h1>
                  <button class="float-right text-3xl" @click="onCancelButtonAddPopUp()">
                      <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                  </button>
              </div>
          </template>
          <fieldset>
              <div class="statuscontainer popupbodycontainer">
                  <div class="formcontainer flex flex-col">
                    <div class="row flex flex-row w-full upperspacing">
                      <div class="row flex flex-row w-full">
                        <div class="w-1/2 pr-2">
                        <label class="controllabel">Finding Id</label>
                        <!-- <input type="text" v-model="findingsObject.findingId" disabled class="inputboxstyling mt-2"/> -->
                        <div class="mt-2 text-sm inputboxstyling">{{ findingsObject.findingId }}</div>
                        <!-- <div v-if="v$.findingsObject.findingId.$error" class="text-red-500">
                          <div class="error-text" v-if="v$.findingsObject.findingId.required.$invalid">Required</div>
                        </div> -->
                        </div>
                        <!-- <div class="w-1/2 pr-2">
                        <label class="controllabel">Category</label>
                        <select class="inputboxstyling cursor-pointer" v-model="findingsObject.findingCategory" :class="{ 'is-invalid': v$.findingsObject.findingCategory.$error }">
                          <option value="" selected disabled hidden>Please select one</option>
                            <option v-for="i in findingCategoryData" :value="i.id" :key="i.id">
                                  {{ i.value }}
                            </option>
                        </select>
                        <div v-if="v$.findingsObject.findingCategory.$error" class="text-red-500">
                              <div class="error-text" v-if="v$.findingsObject.findingCategory.required.$invalid">Required</div>
                        </div>
                        </div> -->
                      </div>
                     </div>
                     <div class="w-full upperspacing">
                          <label class="controllabel">Finding</label>
                          <textarea v-model="findingsObject.finding" class="inputboxstyling mt-2 resize-none" placeholder="Finding" rows="3" :class="{ 'is-invalid': v$.findingsObject.finding.$error }"></textarea>
                          <div v-if="v$.findingsObject.finding.$error" class="text-red-500">
                              <div class="error-text" v-if="v$.findingsObject.finding.required.$invalid">Required</div>
                          </div>
                      </div>
                     <div class="w-full upperspacing">
                        <label class="controllabel">Evidence</label>
                        <!-- <textarea v-model="findingsObject.findingEvidence" class="inputboxstyling resize-none" placeholder="Finding related to Evidence" :class="{ 'is-invalid': v$.findingsObject.findingEvidence.$error }"></textarea> -->
                        <multiselect
                          v-model="findingsObject.findingEvidence"
                          :options="evidenceArray"
                          :show-labels="false"
                          :multiple="false"
                          :hide-selected="true"
                          open-direction="bottom"
                          :searchable="true"
                          :hideArrow="false"
                          placeholder="Evidences"
                          class=" userSelect mt-2"
                          :class="{'is-invalid': v$.findingsObject.findingEvidence.$error}"
                        >
                          <template v-slot:noResult>
                            <span>No Evidences Found</span>
                          </template>
                        </multiselect>
							<div v-if="v$.findingsObject.findingEvidence.$error" class="text-red-500">
								<div class="error-text" v-if="v$.findingsObject.findingEvidence.required.$invalid">Required</div>
							</div>
						</div>
                  </div>
              </div>
              <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                  <button class="mr-1.5" :class="JSON.stringify(findingsObject) !== JSON.stringify(findingsObjectDummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn()">Cancel</button>
                  <button class="" :class="JSON.stringify(findingsObject) !== JSON.stringify(findingsObjectDummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" :disabled="clickOnButtonForFinding" @click="addFindingButton()">Save</button>
              </div>
          </fieldset>
      </popup>
      <popup v-if="showPopUpForResult" :popUp="showPopUpForResult">
      <template v-slot:header>
            <div class="flex items-center justify-between m-4 rounded">
                <h1>Result</h1>
                <button class="float-right text-3xl" @click="onCancelButtonAddPopUpForResult()">
                    <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                </button>
            </div>
        </template>
        <fieldset>
          <!-- {{resultObject}} -->
            <div class="statuscontainer popupbodycontainer">
              <div class="formcontainer flex flex-col">
                <div class="row flex flex-row w-full upperspacing">
                  <div class="row flex flex-row w-full">
                    <div class="w-1/2 pr-2">
                      <label class="controllabel">Result for</label>
                      <!-- this.resultObject.resultfor == 1 -->
                      <select 
                      v-if="conditionForControlOrKPI && presentTaskObject.kpis"
                      class="inputboxstyling cursor-pointer mt-2"
                      @change="getObj()"
                      disabled
                      v-model = resultObject.resultfor
                      :class="{ 'is-invalid': v$.resultObject.resultfor.$error }"
                      >
                        <option value="" selected disabled hidden>Please select one</option>
                        <option v-for="i in resultArray" :value="i.id" :key="i">
                            {{ i.value }}
                        </option>
                    </select>
                    <select 
                      v-if="presentTaskObject.kpis && !conditionForControlOrKPI"
                      class="inputboxstyling cursor-pointer mt-2"
                      @change="getObj()"
                      v-model = resultObject.resultfor
                      :class="{ 'is-invalid': v$.resultObject.resultfor.$error }"
                      >
                        <option value="" selected disabled hidden>Please select one</option>
                        <option v-for="i in resultArray" :value="i.id" :key="i">
                            {{ i.value }}
                        </option>
                    </select>
                    <div v-if="resultObject.resultfor == 1 && !presentTaskObject.kpis">
                      <input v-for="i in resultArray" :key="i.value" class="inputboxstyling cursor-pointer mt-2" v-model=i.value disabled>
                    </div>
                      <!-- <select 
                      class="inputboxstyling cursor-pointer"
                      v-if="controlObject"
                      v-model="resultObject.resultArray1"
                      >
                      <option value="" selected disabled hidden>Please select one</option>
                        <option v-for="i in resultArray1" :value="i.id" :key="i">
                            {{ i.value }}
                        </option>
                      </select> -->
                      <!-- <select 
                      class="inputboxstyling cursor-pointer"
                      v-if="controlResultsForCompliance.length == 1"
                      
                      v-model = resultObject.resultfor
                      :class="{ 'is-invalid': v$.resultObject.resultfor.$error }"
                      >
                        <option value="" selected disabled hidden>Please select one</option>
                        <option v-for="i in resultArray2" :value="i.id" :key="i">
                            {{ i.value }}
                        </option>
                    </select> -->
                    
                    <!-- <select 
                      @change="getObj()"
                      class="inputboxstyling cursor-pointer"
                      v-else-if="presentTaskObject.kpis && presentTaskObject.controlNumber && !(storingKpiIds.length != 0)"
                      v-model = resultObject.resultfor
                      :class="{ 'is-invalid': v$.resultObject.resultfor.$error }"
                      >
                        <option value="" selected disabled hidden>Please select one</option>
                        <option v-for="i in resultArray" :value="i.id" :key="i">
                            {{ i.value }}
                        </option>
                    </select> -->
                    <!-- <div v-else-if="!presentTaskObject.kpis || (storingKpiIds.length != 0)">
                      <input v-for="i in resultArray1" :key="i.value" class="inputboxstyling cursor-pointer" v-model=resultObject.resultfor disabled>
                    </div> -->
                    <!-- <select 
                      class="inputboxstyling cursor-pointer"
                      v-else-if="!presentTaskObject.kpis || (storingKpiIds.length != 0)"
                     @change="getObj()"
                      v-model = resultObject.resultfor
                      :class="{ 'is-invalid': v$.resultObject.resultfor.$error }"
                      >
                        <option value="" selected disabled hidden>Please select one</option>
                        <option v-for="i in resultArray1" :value="i.id" :key="i">
                            {{ i.value }}
                        </option>
                    </select> -->
                    <!-- <input
                          class="inputboxstyling cursor-pointer"
                          v-else-if="!presentTaskObject.kpis || (storingKpiIds.length != 0)"
                          @change="getObj()"
                          v-model = resultObject.resultfor
                          disabled
                          placeholder="ControlNo./KPI No."
                          :class="{ 'is-invalid': v$.resultObject.resultfor.$error }"
                          > -->
                          <!-- <div class="inputboxstyling cursor-pointer" disabled v-else-if="!presentTaskObject.kpis || (storingKpiIds.length != 0)">Control</div> -->
                      <!-- <input type="number" v-model="resultObject.actualOutput" class="inputboxstyling" placeholder="Compliant (A)" :class="{ 'is-invalid': v$.resultObject.actualOutput.$error || !resultOfNegativeOutput }"/> -->
                    <div v-if="v$.resultObject.resultfor.$error" class="text-red-500">
                        <div class="error-text" v-if="v$.resultObject.resultfor.required.$invalid">Required</div>
                      </div>
                    <!-- </div> -->
                    </div>
                    <div class="w-1/2 pr-2">
                      
                      <div v-if="resultObject.resultfor == 1">
                        <label class="controllabel">CCID</label>
                        <input
                          class="mt-2 w-full px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
                          v-if="resultObject.resultfor != 2"
                          v-model = resultObject.controlorKpi
                          placeholder="ControlNo./KPI No."
                          disabled
                          :class="{ 'is-invalid': v$.resultObject.controlorKpi.$error }"
                          >
                      </div>
                      <div v-else-if="resultObject.resultfor == 2">
                        <label class="controllabel">KPI No.</label>
                        <select 
                          class="w-full mt-2 px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
                          @click="getKpis()"
                          v-model = resultObject.controlorKpi
                          :class="{ 'is-invalid': v$.resultObject.controlorKpi.$error }"
                          placeholder="Please select one"
                          >
                            <option value="" selected disabled hidden>Please select one</option>
                            <option v-for="i in kpisArray" :value="i._id" :key="i._id">
                                {{i.description}}
                            </option>
                        </select>
                      </div>
                      <div v-else>
                        <label class="controllabel">Control No./KPI No.</label>
                      <select 
                          class="w-full mt-2 px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
                          
                          :disabled="true"
                          >
                            <option value="" selected disabled hidden>Please select one</option>
                            
                        </select>
                      </div>
                      
                      
                      
                        
                      

                      <!-- <input type="number" v-model="resultObject.totalPopulation" class="inputboxstyling" placeholder="Population" :class="{ 'is-invalid': v$.resultObject.totalPopulation.$error || !resultOfNegativePopulation || !resultOfPopulation }"/> -->
                      <div v-if="v$.resultObject.controlorKpi.$error" class="text-red-500">
                        <div class="error-text" v-if="v$.resultObject.controlorKpi.required.$invalid">Required</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row flex flex-row w-full">
                  <div class="row flex flex-row w-full upperspacing">
                    <div class="w-1/2 pr-2">
                      <label class="controllabel">Complaint</label>
                      <input type="number" @input="handleComplaintDecimalValue" v-model="resultObject.actualOutput" class="inputboxstyling mt-2" placeholder="Actual output" :class="{ 'is-invalid': v$.resultObject.actualOutput.$error || !resultOfNegativeOutput }"/>
                      <div v-if="v$.resultObject.actualOutput.$error || !resultOfNegativeOutput" class="text-red-500">
                        <div class="error-text" v-if="v$.resultObject.actualOutput.required.$invalid">Required</div>
                        <div class="error-text" v-if="(resultObject.actualOutput != undefined || resultObject.actualOutput != null) && (resultObject.actualOutput < 0) && !resultOfNegativeOutput">Value must be non-negative</div>
                      </div>
                    </div>
                    <div class="w-1/2 pr-2">
                      <label class="controllabel">Population</label>
                      <input type="number" @input="handlePopulationDecimalValue" v-model="resultObject.totalPopulation" class="inputboxstyling mt-2" placeholder="Total Population" @keyup="isTotalPopulationValid()" :class="{ 'is-invalid': v$.resultObject.totalPopulation.$error || !resultOfNegativePopulation || !resultOfPopulation }"/>
                      <div v-if="v$.resultObject.totalPopulation.$error || !resultOfNegativePopulation || !resultOfPopulation" class="text-red-500">
                        <div class="error-text" v-if="v$.resultObject.totalPopulation.required.$invalid">Required</div>
                        <div class="error-text" v-if="!resultOfNegativePopulation && !v$.resultObject.totalPopulation.required.$invalid">Value must be non-negative</div>
                        <div class="error-text" v-if="resultOfNegativePopulation &&!resultOfPopulation && !v$.resultObject.totalPopulation.required.$invalid">Population must be greater than or equal to Compliant</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full upperspacing">
                  <label class="controllabel">Comments</label>
                    <textarea v-model="resultObject.comments" class="inputboxstyling resize-none mt-2" placeholder="Comments" rows="3" :class="{ 'is-invalid': v$.resultObject.comments.$error }"></textarea>
                      <div v-if="v$.resultObject.comments.$error" class="text-red-500">
                        <div class="error-text" v-if="v$.resultObject.comments.required.$invalid">Required</div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                  <button class="mr-1.5" :class="JSON.stringify(resultObject) !== JSON.stringify(resultObjectDummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtnForResult()">Cancel</button>
                  <button class="" :class="JSON.stringify(resultObject) !== JSON.stringify(resultObjectDummy) ? 'btnprimary' : 'btndisabled pointer-events-none'" :disabled="clickOnButtonForResult" @click="addResultButtonForTask(resultObject)">Save</button>
              </div>
        </fieldset>
      </popup>
      <popup v-if="importpopUp" :popUp="importpopUp">
          <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex">
          <div class="relative w-full max-w-xl">
          <!--content-->
          <div class="border-0 rounded relative flex flex-col w-full bg-white h-screen">
              <!--header-->
              <div class="flex items-center justify-between popupheadingcontainer rounded">
              <h1>Import files</h1>
              <button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="onCancelIcon()">
                  <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
              </button>
              </div>
              <div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>
              <div class="impactcontainer popupbodycontainer">
              <div class="formcontainer flex flex-col">
                  <div class="row flex flex-col mt-2">
                  <div>
                      <label class="controllabel">File Name </label>
                      <input class="inputboxstyling text-xs mt-2" type="text" v-model="inputFileName" placeholder="Filename"  :class="{'is-invalid': v$.inputFileName.$error }"/>
                      <div v-if="v$.inputFileName.$error " class="text-red-500">
                          <div class="error-text text-xs" v-if="v$.inputFileName.required.$invalid ">Required</div>
                      </div>
                  </div>
                  <div class="pt-3">
                      <label class="controllabel">Attachment</label>
                      <div class="w-auto bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden mt-2" :class="{'is-invalid':v$.fileToUpload.$error}">
                          <!-- <label htmlFor="upload" class="controllabel">Upload File</label> -->
                          <input type="file" ref="fileInput" class="hidden" id="upload" @change="handleFileUpload"/>
                          <label for="upload" class="float-right cursor-pointer text-white flex justify-center heightForDiv items-center w-9 bg-primary"><img src="@/assets/Browse.svg" /></label>
                          <!-- <div v-if="fileName" class="text-xs p-1">{{ fileName }}</div> -->
                           <div v-if="fileName == ''" class="text-sm text-mediumGrey heightForDiv">
                            {{ placeholder }}
                          </div>
                          <div v-if="fileName != ''" class="text-sm heightForDiv">
                            {{ fileName }}
                          </div>
                      </div>
                  </div>
                  
                  <div v-if="v$.fileToUpload.$error" class="text-red-500">
                      <div class="error-text text-xs" v-if="v$.fileToUpload.$invalid">File upload is required</div>
                  </div>
                  </div>
              </div>
              </div>
              <!--footer-->
              <div class="buttonposition flex justify-center items-center">
                  <button class="btnprimary" @click="handleFileChange" :class="(inputFileName.length == 0) ? 'btndisabled pointer-events-none ' : 'btnprimary'" :disabled="clickOnButton">Save</button>
              </div>
          </div>
          </div>
          </div>
      </popup>
    </div>
</template>
<style scoped>
.primary{
	color:#152a43;
}
/* .progressbarB {
	border: 2px solid #e9e9e9;
	background: #e9e9e9;
	position: relative;
	top: -8px;
	z-index: 1;
} */

/* .Vue-Toastification__container {
	z-index: 999999999 !important;
} */
 .btndisabledForFilter{
  border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(233 233 233 / var(--tw-bg-opacity));
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    font-family: Open Sans;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: .75rem;
    --tw-text-opacity: 1;
    color: rgb(77 77 77 / var(--tw-text-opacity));

 }
 .heightForDiv{
  height : 29.333px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
 .taskStatusOptions{
  background-color: white; 
  color: rgba(77, 77, 77, var(--tw-text-opacity))
 }
 .taskStatusOptionsForNotStarted{
    background-color: #e9e9e9; 
  color: rgba(77, 77, 77, var(--tw-text-opacity))
 }
 .table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.paddingforDescription{
  padding-left:1.9rem;
}
.paddingrightforDescription{
  padding-right:0.3rem;
}
.numberBorder {
  border-top: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.numberNavy {
  border-left: 1px solid #152a43;
  border-left-width: 2px;
}
.progress {
  background: #152a43;
  position: relative;
  border-radius: 4px;
  z-index: 2;
}
.primaryBackground {
  background-color: #152a433b;
}
.descriptionToggle {
  height:  calc(100vh - 230px);
}
.tabStructure {
	height: calc(100vh - 165px);
}
.descriptionToggle::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}
.w-30{
  width:30%;
}
.w-20{
  width:20%;
}
.w-23{
  width:23%;
}
.w-27{
  width:27%;
}
.w-50{
  width:50%;
}
.fontSize10PX{
 font-size: 10px;
}
.descriptionToggle::-webkit-scrollbar-thumb {
	background: transparent;
}
.heightFixing {
	height: calc(100vh - 220px);
}
.thirtyWidthToggle::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}

.thirtyWidthToggle::-webkit-scrollbar-thumb {
	background: transparent;
}
.thirtyWidthToggle{  
     height: calc(100vh - 250px);
}
option {
  background-color: #ffffff;
  color: #000000;
  border-color: pink transparent transparent transparent;
}
.textColorWhite {
  color: white;
}
.pendingEvidence {
  background-color: #8600f040;
}
.evidenceUpload {
  background: rgba(21, 39, 60, 0.24);
}
.labelWidth {
  min-width: 7rem;
}

.progressbarB {
  border: 2px solid #e9e9e9;
  background: #e9e9e9;
  position: relative;
  top: -8px;
  border-radius: 5px;
}
.framework{
    padding: 12px 170px 12px 12px;
}
.custom-select {
  background-image: url("@/assets/Collapse.svg") !important;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: 12px 12px;
  background-position: right 15px center; /* Change the px value here to change the distance */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.progress {
  border-radius:16px;
  background: #152a43;
}
.inputboxstyling1 {
  display: block;
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgba(233, 233, 233, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  /* background-color: rgba(255, 255, 255, var(--tw-bg-opacity)); */
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 300;
  --tw-text-opacity: 1;
  /* color: rgba(77, 77, 77, var(--tw-text-opacity)); */
}
.colorWhite {
  color: white;
}
.adjust {
	flex-shrink: 0;
}
.progress-bar::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  /* margin-left: 5px;
  margin-right: 5px; */
  width: 20px; /* Adjust the thickness of the line as needed */
  /* background-color: #fff; Color of the indicator line performanceIndicator */ 
  /* background-image: url("@/assets/performanceIndicatior.svg") !important; */
  /* background-repeat: no-repeat no-repeat;
  background-position: center center; */
  /* left: calc(var(--progress)); Position based on progress */
}
.progress-bar100::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  /* margin-left: 5px;
  margin-right: 5px; */
  width: 20px; /* Adjust the thickness of the line as needed */
  /* background-color: #fff; Color of the indicator line performanceIndicator */ 
  background-image: url("@/assets/performanceIndicatior.svg") !important;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  left: calc(var(--progress100) - 20px);

  /* right: calc(var(--progress) - 15px); */
}
.progress-container {
  width: 100%;
  height: 15px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(
    to right,
    #c00000 0%,
    #ff0000 20%,
    #ff9900 40%,
    #92d050 60%,
    #00b050 100%
  ) !important;
  position: relative;
  --progress: 0%;
}
.progress-bar100 {
  height: 100%;
  background: linear-gradient(
    to right,
    #c00000 0%,
    #ff0000 20%,
    #ff9900 40%,
    #92d050 60%,
    #00b050 100%
  ) !important;
  position: relative;
  --progress100: 0%;
}
.onepixel{
  padding-bottom : 0.5px;
}
.progress-indicator-container {
  position: relative;
  height: 15px;
  font-size: 12px;
  /* margin-top: 5px; */
}
.progress-indicator-count {
  position: relative;
  height: 13px;
  font-size: 12px;
  margin-top: -4px;
}
.indicatorIcon100::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  /* margin-left: 5px;
  margin-right: 5px; */
  width: 18px; /* Adjust the thickness of the line as needed */
  /* background-color: #fff; Color of the indicator line performanceIndicator */ 
  background-image: url("@/assets/performanceIndicatior.svg") !important;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  transform: rotate(180deg);
  left: calc(var(--progress100) - 20px);
  /* right: calc(var(--progress) - 15px); */
}
.indicatorIcon{
  height: 100%;
  width: 18px;
  background-image: url("@/assets/performanceIndicatior.svg") !important;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  transform: rotate(180deg);
  position: relative;
  left: calc(var(--progress));
}
.indicator {
  position: absolute;
  /* top: 50%;
  left: 50%;
  color: rgb(0, 0, 0); */
}

</style>
<script lang="ts">
import { defineComponent } from "vue";
// import breadCrumbs from '@/components/breadCrumbs.vue';
import breadCrumb from "@/components/breadCrumb.vue";
import { helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import popup from "@/components/popup.vue";
import { groupBy } from "lodash";
import { emitter, toast } from "@/main";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import tableData from "@/components/tableData.vue";
import commentsEditor from "@/components/commentsEditor.vue";
import safeHtml from "@/components/safeHtml.vue";

export default defineComponent({
  data(): any {
    return {
      v$: useVuelidate(),
      selectedTabIndex: 1,
      tabHead: [
        { displayName: "Description", id: 1 },
        { displayName: "Documents", id: 2 },
        { displayName: "Related Tasks", id: 3 },
        { displayName: "Evidence", id: 4 },
        { displayName: "Comments", id: 5 },
        { displayName: "Findings", id: 6 },
        { displayName: "Result", id: 7 },
        { displayName: "History", id: 8 },
      ],
      routesArray: [],
      progress: 0,
      tableRow: [],
      length:[],
      Headers: ['TaskId','Title','Frequency','Status'],
      progressBarColor: "linear-gradient(to right, red, green)",
      tasksForCompliance: [],
      presentTaskObject: {},
      kpisObject: [],
      controlDetailsArray: [],
      presentComplianceObject: [],
      teamLeaderValue: '',
      optionsUserArray: [],
      optionsUsersList: [],
      inputFileName: '',
      severity:'',
      fileName: '',
      resultArray1:[
      {
          id: 1,
          value: 'Control'
        },
        
      ],
      resultArray2:[
        {
          id: 2,
          value: 'KPI'
        }
      ],
      resultArray:[
      {
          id: 1,
          value: 'Control'
        },
        {
          id: 2,
          value: 'KPI'
        }
      ],
      controlsArray: [],
      TeamOwnerOptions:[],
      // greaterThanOrEqualActual: false,
      orgUsersList: [],
      users: [],
      allEntities: [],
      newHistory: true,
      locationObject: [],
      controlFrequencyArray: [
        { label: 'Transactional', id: 1 },
				{ label: 'Daily', id: 2 },
				{ label: 'Weekly', id: 3 },
				{ label: 'Monthly', id: 4 },
				{ label: 'Quarterly', id: 5 },
				{ label: 'Semi Annualy', id: 6 },
				{ label: 'Annualy', id: 7 },
				{ label: 'Ad-Hoc', id: 8 }
      ],
      findingCategoryData: [
                {
                    id: 1,
                    value: 'Minor'
                },
                {
                    id: 2,
                    value: 'Major'
                },
                {
                    id: 3,
                    value: 'OFI'
                },
                {
                    id: 4,
                    value: 'Observation'
                }
        ],
      kpislength:'',
      TaskDetailsDropDown: {
        taskStatus: "",
        assigneeDropDown: "",
      },
      colorData: [
        {
          id: 1,
          progressValue: "Very Low Risk",
          color: "bg-lightgreen",
          kpiResultValue: "Very High Performance",
        },
        {
          id: 2,
          progressValue: "Low Risk",
          color: "bg-green",
          kpiResultValue: "High Performance",
        },
        {
          id: 3,
          color: "bg-yellow",
          progressValue: "Moderate Risk",
          kpiResultValue: "Moderate Performance",
        },
        {
          id: 4,
          color: "bg-error",
          progressValue: "High Risk",
          kpiResultValue: "Low Performance",
        },
        {
          id: 5,
          color: "bg-darkred",
          progressValue: "Very High Risk",
          kpiResultValue: "Very Low Performance",
        },
      ],
      // taskStatusData: [
      //     {
      //         id: 10401,
      //         status: 'Not Started'
      //     },
      //     {
      //         id: 10415,
      //         status: 'In Review'
      //     },
      //     {
      //         id: 10414,
      //         status: 'Pending Evidence'
      //     },
      //     {
      //         id: 10416,
      //         status: 'Evidence Uploaded'
      //     },
      //     {
      //         id: 10417,
      //         status: 'Completed'
      //     }
      // ],
      storingKpiIds:[],
      severityValues:[
        {
          id:5,
          value:'Very Low'
        },
        {
          id:4,
          value:'Low'
        },
        {
          id:3,
          value:'Moderate'
        },
        {
          id:2,
          value:'High'
        },
        {
          id:1,
          value:'Very High'
        }
      ],
      taskStatusData: [
        {
          id: 10401,
          status: "Not Started",
        },
        {
          id: 10415,
          status: "In Review",
        },
        {
          id: 10414,
          status: "Pending Evidence",
        },
        {
          id: 10416,
          status: "Evidence Uploaded",
        },
        {
          id: 10403,
          status: "Completed",
        },
        {
          id: 10417,
          status: "Not Applicable",
        },
      ],
      terminatedStatus: [
        {
          id: 10418,
          status: "Terminated",
        }
      ],
      kpisArray:[],
      assigneeData: [],
      descriptionObject: {},
      kpi_data: [],
      controlRecurringArray: [
        { label: "Daily", id: 1 },
        { label: "Weekly", id: 2 },
        { label: "Monthly", id: 3 },
        { label: "Quarterly", id: 4 },
        { label: "Half-Yearly", id: 5 },
        { label: "Yearly", id: 6 },
      ],
      controlObject : false,
      kpiProgress: 0,
      controlProgress: 0,
      controlResultsForCompliance: [],
      oldComments: true,
      newComment: "",
      titleLength: 0,
      comments: [],
      showPopUp: false,
      resultOfPopulation: true,
      resultOfNegativeOutput: true,
      conditionForControlOrKPI: false,
      resultOfNegativePopulation: true,
      showPopUpForResult:false,
      comment: "",
      fileToUpload: '',
      btnDisabled: true,
      importpopUp: false,
      findingsForCompliance: [],
      resultsForCompliance: [],
      complianceFrameworkArray: [],
      allFrameWorkControls: [],
      eachControlDocuments: [],
      workLogObject: [],
      findingsObject: {
        findingId: '',
        findingEvidence: "",
        // findingCategory: "",
        finding: "",
      },
      findingsObjectDummy: {
        findingId: '',
        findingEvidence: "",
        // findingCategory: "",
        finding: "",
      },
      resultObject: {
        resultfor:"",
        controlorKpi:"",
        actualOutput: "",
        totalPopulation: "",
        comments: "",
      },
      resultObjectDummy: {
        resultfor:"",
        controlorKpi:"",
        actualOutput: "",
        totalPopulation: "",
        comments: "",
      },
      kpiDetailsArray:[],
      getControlNumber:[],
      reportingFrequency: "",
      selectedBG: "bg-disabledgrey colorWhite",
      newTeamLeader : '',
      clickOnButton: false,
      clickOnButtonForFinding : false,
      clickOnButtonForResult : false,
      evidenceArray:[],
      placeholder: 'Select a File',
    };
    
  },

  validations() {
    return {
      findingsObject: {
        // findingId: {required},
        finding: { required },
        findingEvidence:{required},
        // findingCategory:{required},
      },
      resultObject: {
        resultfor:{required},
        controlorKpi:{required},
        actualOutput: {required},
        totalPopulation: {required},
        comments: {required},
      },
      inputFileName: { required },
      fileToUpload: { required }
    };
  },
  components: {
    breadCrumb,
    popup,
    Multiselect,
    tableData,
    commentsEditor,
    safeHtml
  },
  mounted(){
    emitter.off('addedComments');
    emitter.on('addedComments', (data: any) => {
      console.log("addedComments", data)
      this.comment = data.comments
			this.addComment();
		});
  },
  beforeUnmount() {
		 sessionStorage.removeItem('selectedTab')
	 },
  async created() {
    const savedTabKey = sessionStorage.getItem('selectedTab');
    console.log("savedTabKey",savedTabKey)
		if (savedTabKey) {
      console.log("tabHead",this.tabHead)
        const savedTab = this.tabHead.find((tab: any) => tab.id == savedTabKey);
        if (savedTab) {
			 this.toggleTabs(savedTab);
        }
		else{
            await this.toggleTabs({ displayName: "Description", id: 1 });
		}
    }
	else{
	await this.toggleTabs({ displayName: "Description", id: 1 });
	}
    // await this.toggleTabs({ displayName: "Description", id: 1 });
    await this.getControls();
    // await this.getControlDocuments();
    await this.getallEntities();
    await this.getallUsers();
    await this.getAllTeams();
    await this.getPresentComplianceObject();
    await this.getSingleComplianceFindings();
    await this.getTaskHistoryObject();
    await this.getTasksForCompliance(
      this.$route.params.complianceId,
      this.$route.params.taskId
    );
    // this.controlEditPopupDummy = { ...this.controlEditPopup };
    let complianceId = this.$route.params.complianceId;
    if(this.presentTaskObject.kpis == null || this.presentTaskObject.kpis == undefined && this.presentTaskObject.kpis.length == null || this.presentTaskObject.kpis.length == undefined){
      this.controlObject = true;
    }else{
      this.controlObject = false;
    }
    this.titleLength = this.presentTaskObject.controlTitle ? this.presentTaskObject.controlTitle.length:0

    // this.routesArray = [
    //   { name: "Compliance", routeName: "createCompliance" },
    //   // { name: 'Task Status / Analytics', routeName: 'view-compliance'},
    //   {
    //     name: "Compliance-Tasks",
    //     routeName: "start-Compliance",
    //     id: complianceId,
    //   },
    //   { name: this.presentTaskObject.taskId, routeName: "edit" },
    // ];
    
    if(this.presentTaskObject.results){
      this.presentTaskObject.results.forEach((element : any) => {
      this.kpiDetailsArray.push(element.controlorKpi)
      
    });
    
    this.length = this.presentTaskObject.results.length
    }
    if(this.presentTaskObject.kpis){
      this.kpislength = this.presentTaskObject.kpis.length
    }
    if (Object.keys(this.$route.query).includes("myTasks")) {
      this.routesArray = [
        { name: "My Tasks", routeName: "myTasks" },
        { name: this.presentTaskObject.taskId, routeName: "complianceTasks" },
      ];
    } else {
      this.routesArray = [
        { name: 'Compliance', routeName: "compliance" },
        { name: this.presentComplianceObject.complianceId, routeName: "viewCompliance" },
        { name: "Tasks", routeName: "start-Compliance" },
        { name: this.presentTaskObject.taskId, routeName: "complianceTasks" },
      ];
    }
    this.conditionForResultBtn();
    if(this.presentComplianceObject.teamLeader != undefined){
        this.newTeamLeader = this.presentComplianceObject.teamLeader
    }
  },
  computed: {
    ...mapGetters({ userInfo: "userInfo" }),
    filteredTaskStatusData: function ():any {
        return this.taskStatusData.filter((i:any) => {
          console.log("!((i.id == 10403 || i.id == 10417) && this.teamLeaderValue != this.userInfo._id)", !((i.id == 10403 || i.id == 10417) && this.teamLeaderValue != this.userInfo._id))
            return !((i.id == 10403 || i.id == 10417) && this.teamLeaderValue != this.userInfo._id);
        });
    }
  },
  methods: {
    getKpis(){
      if(this.presentTaskObject.kpis && this.resultsForCompliance.length == 0){
        this.kpisArray = this.presentTaskObject.kpis
      } else if(this.presentTaskObject.kpis && this.resultsForCompliance.length == 1){
        const resultsKpiDescriptions = this.presentTaskObject.results.map((result: any) => result.controlorKpi);

        // Filter the presentTaskObject.kpis array to exclude KPIs that are in resultsKpiDescriptions
        this.kpisArray = this.presentTaskObject.kpis.filter((kpi: any) => !resultsKpiDescriptions.includes(kpi._id));

        // Log the results for debugging purposes
      }
    },
    conditionForResultBtn(){
      // presentTaskObject.results.length
      // console.log("this.TaskDetailsDropDown.taskStatus", this.TaskDetailsDropDown.taskStatus);
      // console.log("check", (Object.keys(this.presentTaskObject).includes('results') || this.presentTaskObject.kpis == undefined || this.presentTaskObject.kpis == null && this.presentTaskObject.results.length != this.presentTaskObject.kpis.length) && (Object.keys(this.presentTaskObject).includes('controlResults') && this.presentTaskObject.controlResults.length != 0))
      let condition = this.TaskDetailsDropDown.taskStatus == 10403 || this.TaskDetailsDropDown.taskStatus == 10417 || this.TaskDetailsDropDown.taskStatus == 10418 || this.TaskDetailsDropDown.taskStatus == 10401;
      let secondCondition = false;
      let thirdCondition = false;
      // let fourthCondition = false;
      if(Object.keys(this.presentTaskObject).includes('kpis')){
        thirdCondition = (Object.keys(this.presentTaskObject).includes('controlResults') && this.presentTaskObject.controlResults.length != 0);
        secondCondition = (Object.keys(this.presentTaskObject).includes('results') && this.presentTaskObject.results.length == this.presentTaskObject.kpis.length)
      }
      console.log("condition", condition)
      console.log("secondCondition", secondCondition)
      console.log("thirdCondition", thirdCondition)
      // console.log("fourthCondition", fourthCondition)
      if(condition || (secondCondition && thirdCondition) ){
        return true;
      }else{
        return false;
      }
    },
    getObj(){
      if(this.resultObject.resultfor == 1){
        this.resultObject.controlorKpi = this.presentTaskObject.clientUniqueId
      } else if(this.resultObject.resultfor == 2){
        this.resultObject.controlorKpi = ""
        // this.resultArray = 
      }
    },
    asyncFind1(query:any){
            if(query == ''){
                this.TeamOwnerOptions = []
            }else if(query.length > 1){
                this.TeamOwnerOptions = this.optionsUsersList.filter((obj:any)=>{
                  if(obj.value != this.presentTaskObject.reviewer){
                    return obj.label.toLowerCase().includes(query.toLowerCase());
                  }
                })
            }
    },
    updateProgress(value: any) {
      this.progress = value;
    },
    // async updateTaskStatus(status: any) {
    //   let payload: any = { statusCode: status };
    //   await this.updateComplianceTask(4, payload);
    // },
    async updateTaskStatus(status: any) {
      let payload: any = { statusCode: status };
      if(status == 10403){
        if((this.presentTaskObject.controlResults != undefined || this.presentTaskObject.controlResults != null) && this.presentTaskObject.controlResults.length>0){
          await this.updateComplianceTask(4, payload);
        } else {
          this.TaskDetailsDropDown.taskStatus = this.presentTaskObject.statusCode;
          toast.error(`Add Control Result`, {
            timeout: 3000,
            closeOnClick: true,
            closeButton: "button",
            icon: true,
          });
        }
      } else {
        await this.updateComplianceTask(4, payload);
      }
      
    },
    colorRiskText(severity: any) {
            if (severity == 5) {
                return ['text-lightgreen text-xs font-semibold'];
            } else if (severity == 4) {
                return ['text-green text-xs font-semibold'];
            } else if (severity == 3) {
                return ['text-yellow text-xs font-semibold'];
            } else if (severity == 2) {
                return ['text-error text-xs font-semibold'];
            } else if (severity == 1) {
                return ['text-darkred text-xs font-semibold'];
            }
        },
      colorRiskBg(severity: any) {
            if (severity == 5) {
                return ['bg-lightgreenBg border-solid border border-lightgreen'];
            } else if (severity == 4) {
                return ['bg-greenBg border-solid border border-green'];
            } else if (severity == 3) {
                return ['bg-yellowBg border-solid border border-yellow'];
            } else if (severity == 2) {
                return ['bg-errorBg border-solid border border-error'];
            } else if (severity == 1) {
                return ['bg-darkredBg border-solid border border-darkred'];
            }
        },
 
    changeBackground() {
      if (this.TaskDetailsDropDown.taskStatus == 10401) {
        return "bg-disabledgrey textColorWhite";
      } else if (this.TaskDetailsDropDown.taskStatus == 10415) {
        return "bg-yellow textColorWhite";
      } else if (this.TaskDetailsDropDown.taskStatus == 10414) {
        return "bg-electricGreen textColorWhite";
      } else if (this.TaskDetailsDropDown.taskStatus == 10416) {
        return "bg-primary textColorWhite";
      } else if (this.TaskDetailsDropDown.taskStatus == 10403) {
        return "bg-lightgreen textColorWhite";
      } else if (this.TaskDetailsDropDown.taskStatus == 10417) {
        return "bg-darkred textColorWhite";
      } else if (this.TaskDetailsDropDown.taskStatus == 10418) {
        return "bg-error textColorWhite";
      } else {
        return "";
      }
    },
    colorComplianceText(status: any) {
      if (status == 10401) {
        return ["font-semibold text-xs text-disabledgrey"];
      } else if (status == 10402 || status == 10415) {
        return ["font-semibold text-xs text-yellow"];
      } else if (status == 10403) {
        return ["font-semibold text-xs text-lightgreen"];
      } else if (status == 10414) {
        return ["font-semibold mx-2 text-xs text-electricGreen"];
      } else if (status == 10416) {
        return ["font-semibold mx-2 text-xs text-primary"];
      } else if (status == 10417) {
        return ["font-semibold mx-2 text-xs text-darkred"];
      } else if (status == 10418) {
        return ["font-semibold mx-2 text-xs text-error"];
      }
    },
    colorComplianceBg(status: any) {
      if (status == 10401) {
				return ['bg-lightgrey border-solid border border-disabledgrey'];
			} else if (status == 10402 || status == 10415) {
				return ['bg-yellowBg border-solid border border-yellow'];
			} else if (status == 10403) {
				return ['bg-lightgreenBg border-solid border border-lightgreen'];
			} else if (status == 10414) {
				return ['pendingEvidence border-solid border border-electricGreen'];
			} else if (status == 10416) {
				return ['evidenceUpload border-solid border border-primary'];
			}else if (status == 10417) {
				return ['bg-darkredBg border-solid border border-darkred'];
			}else if (status == 10418) {
				return ['bg-darkredBg border-solid border border-error'];
			}
    },
    async toggleTabs(obj: any) {
      this.selectedTabIndex = obj.id;
      this.selectedTabObj = obj;
      sessionStorage.setItem('selectedTab', obj.id);
    },
    sortWorkLog() {
      this.newHistory = !this.newHistory;
      if(this.workLogObject.taskHistory.length != 0){
        this.workLogObject.taskHistory.sort().reverse();
      }
    },
    sortComments() {
      this.oldComments = !this.oldComments;
      if(Object.keys(this.presentTaskObject).includes('comments') && this.presentTaskObject.comments.length != 0){
        this.presentTaskObject.comments.sort().reverse();
      }
    },
    getSeverityLabel(cameId:any){
        let labelObj = this.severityValues.find((l:any) => {return l.id == cameId;})
        if(labelObj == undefined){
            return '-'
        }else{
            return labelObj.value;
        }
    },
    formatTime(time: any) {
      let now = moment(time);
      return now.format("DD-MM-YYYY, hh:mm:ss");
    },
    extractInitials(name: any) {
      const names = name.split(" ");
      return names
        .map((name: any) => name[0])
        .join("")
        .toUpperCase();
    },
    getPersonName(person: any) {
      let personObj = this.orgUsersList.find((user: any) => {
        return user._id == person;
      });
      if (personObj == undefined) {
        return "";
      } else {
        return personObj.name;
      }
    },
    getTeamName(team: any) {
      let teamObj = this.users.find((obj: any) => {
        return obj._id == team;
      });
      if (teamObj == undefined) {
        return "";
      } else {
        return teamObj.teamName;
      }
    },
    getLocation(teamId: any) {
      let presentteamObject: any = this.users.find((obj: any) => {
        return obj._id == teamId;
      });
      if (presentteamObject != undefined && presentteamObject.type == 20301) {
        let entityObj = this.allEntities.find((entity: any)=>{return entity.entityType == 1})
        return entityObj ? entityObj.entityName : 'N/A';
      } else {
        if (
          this.presentTaskObject.location == undefined ||
          this.presentTaskObject.location == null
        ) {
          return "N/A";
        } else {
          return this.getLocationName(
            this.presentTaskObject.location[0].entity
          );
        }
      }
    },
    async getTaskHistoryObject() {
      let complianceId = this.$route.params.complianceId;
      await this.$http
        .get(
          `${process.env.VUE_APP_MONITOR_API_URL}/api/org/task/${this.$route.params.taskId}/getHistory`
        )
        .then((result: any) => {
          const mainObject = result.data;
          this.workLogObject = mainObject;
          if(this.workLogObject.taskHistory && this.workLogObject.taskHistory.length != 0){
            this.workLogObject.taskHistory.sort().reverse();
          }
        });
    },
    getLocationName(locationId: any,entitytypecodeid:any) {
      if(entitytypecodeid == 20301){
        // let locationObj = this.allEntities.find((entity: any)=>{return entity.entityType == 1})
        // return locationObj.entityName
        return 'Global'
      }else if(locationId == null){
        return 'N/A'
      }else if(entitytypecodeid != 20301){
      let locationObj = this.locationObject.find((obj: any) => {
        return obj.value == locationId;
      });
      if (locationObj == undefined) {
        let empty = "N/A";
        return empty;
      } else {
        return locationObj.label;
      }
      }
    },
    async getallEntities() {
      this.locationObject = [];
      let result: any;
      await this.$http
        .get(
          `${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`
        )
        .then((res: any) => {
          result = res.data;
          this.allEntities = res.data;
        });
      for (var i = 0; i < result.length; i++) {
        this.locationObject.push({
          label: result[i].entityName,
          value: result[i]._id,
        });
      }
    },
    // getPerson(person: any) {
    // 	this.personObj = this.orgUsersList.find((user: any) => {

    // 		return user._id == person;
    // 	});
    // 	if (this.personObj == undefined) {
    // 		return '';
    // 	} else {
    // 		return this.personObj.name;
    // 	}
    // },
    showpopupOnAdd() {
      this.showPopUp = !this.showPopUp;
      if (this.showPopUp == true) {
        let uuid = uuidv4();   
		    const truncatedUuid = uuid.replace(/-/g,'').substring(0, 8)
        this.findingsObject.findingId = truncatedUuid;
        this.findingsObjectDummy.findingId = truncatedUuid;
        delete this.findingsObject.createdBy
        delete this.findingsObject.createdAt 
        delete this.findingsObject.additionalInfo
      }
    },

    showpopupOnAddForResult() {
      this.showPopUpForResult = !this.showPopUpForResult;
      if(!this.presentTaskObject.kpis || Object.keys(this.presentTaskObject).includes('results') && Object.keys(this.presentTaskObject).includes('kpis') && this.presentTaskObject.results.length == this.presentTaskObject.kpis.length){
        this.resultArray = this.resultArray1
        this.conditionForControlOrKPI = true;
        this.resultObject.resultfor = 1;
        if(this.resultObject.resultfor == 1){
          this.resultObject.controlorKpi = this.presentTaskObject.clientUniqueId
        }
      }
      if((this.controlResultsForCompliance != null || this.controlResultsForCompliance != undefined) && Object.keys(this.presentTaskObject).includes('controlResults') && this.presentTaskObject.controlResults.length == this.controlResultsForCompliance.length){
        this.resultArray = this.resultArray2
        this.conditionForControlOrKPI = true;
        this.resultObject.resultfor = 2;
        if(this.resultObject.resultfor == 2){
          this.resultObject.controlorKpi = ""
        }
      }
      this.resultObjectDummy = {...this.resultObject}
    },
    onCancelButtonAddPopUp() {
      this.showPopUp = false;
      this.onCancelbtn();
    },
    onCancelIcon(){
      this.v$.$reset();
      this.importpopUp = false;
      this.inputFileName = '';
      this.fileToUpload = '';
      this.fileName = "";
    },
    onCancelButtonAddPopUpForResult() {
      this.v$.$reset();
      this.v$.$touch();
      this.showPopUpForResult = false;
      this.resultObject.resultfor = "";
      this.resultObject.controlorKpi = "";
      this.resultObject.actualOutput = "";
      this.resultObject.totalPopulation = "";
      this.resultObject.comments = "";
      this.resultOfPopulation= true;
      this.resultOfNegativeOutput= true;
      this.resultOfNegativePopulation= true;
      this.onCancelbtnForResult();
    },
    getStatusLabel(num: any) {
      let result
      if(num == 10418){
        result = this.terminatedStatus.find((obj: any) => {
          return obj.id == num;
        });
        } else{
          result = this.taskStatusData.find((obj: any) => {
          return obj.id == num;
        });
      }
      // result = this.taskStatusData.find((obj: any) => {
      //     return obj.id == num;
      // });
      
      return result.status;
    },
    getBorder() {
      return `numberBorder numberNavy`;
    },
    handleInput() {
      this.btnDisabled = this.comment.length === 0;
    },
    handleComplaintDecimalValue(event:any){
      // Only allow numbers (0-9) without decimals
      this.resultObject.actualOutput = event.target.value.replace(/[^\d]/g, '');
    },
    handlePopulationDecimalValue(event:any){
      // Only allow numbers (0-9) without decimals
      this.resultObject.totalPopulation = event.target.value.replace(/[^\d]/g, '');
    },
    async addComment() {
      if (this.comment.trim() !== "") {
        const newComment: any = {
          comment: this.comment,
        };
        await this.updateComplianceTask(1, newComment);
        // this.comments.push(newComment);
        this.comment = "";
        this.btnDisabled = true;
      }
    },
    // clearComment() {
    //   this.comment = "";
    //   this.btnDisabled = true;
    // },
    openFilePicker() {
      // this.$refs.fileInput.click();
      this.importpopUp = true;
    },
    handleFileUpload(event: any) {
        const file = this.$refs.fileInput.files[0];
        if (file) {
            this.fileName = file.name;
            this.fileToUpload = file
              const fileNameParts = file.name.split('.');
            fileNameParts.pop(); // Remove the extension
            const nameWithoutExtension = fileNameParts.join('.');
            if (this.inputFileName.trim() === '') {
            this.inputFileName = nameWithoutExtension;
            }
        } else {
            this.fileName = '';
            this.inputFileName = '';
        }
    },  
    async handleFileChange(event: any) {
      let data = this.$refs.fileInput.files[0];
      this.v$.inputFileName.$touch()
      this.v$.fileToUpload.$touch()
      if(((!this.v$.fileToUpload.$invalid) && (!this.v$.inputFileName.$invalid))){
        this.clickOnButton = true;
      this.importpopUp = !this.importpopUp
        let fileExtension = data.name.split(".").pop();
        if (fileExtension == "pdf") {
          const fileDataFormat = await this.getConvertDataForFile(data);
          let uuid = uuidv4();
          let payload = {
            fileData: fileDataFormat,
            name: `${uuid}.${fileExtension}`,
          };
          let folderName = "compliance";
          try {
            await this.$http
              .post(
                `${process.env.VUE_APP_FILE_API_URL}/bucket/${folderName}/file/upload`,
                payload
              )
              // .post(`http://localhost:4030/services/filemanagement/bucket/${folderName}/file/upload`, payload)
              // bucket/:folderName/file/upload
              .then(async (response: any) => {
                if (response.status == 200) {
                  let payload: any = {
                    name: `${uuid}.${fileExtension}`,
                    url: response.data,
                    filename: this.inputFileName,
                  };
                  await this.updateComplianceTask(3, payload);
                  this.inputFileName = ''
                  this.fileName = ''
                  this.v$.$reset();
                } else {
                  console.log("response status");
                }
              });
          } catch (err) {
            console.error(err);
          }finally {
            this.clickOnButton = false;
          }
        } else {
          toast.error(`Upload only pdf`, {
            timeout: 1000,
            closeOnClick: true,
            closeButton: "button",
            icon: true,
          });
        }
        this.fileName = '';
        this.inputFileName = '';
			  this.fileToUpload=null
      }
    },
    async updateComplianceTask(type: any, payload: any) {
      payload.createdBy = this.userInfo._id;
      payload.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
      if (type == 1) {
        // comments
        payload.additionalInfo = { comment: "" };
        payload.additionalInfo.comment = payload.comment;
      } else if (type == 2) {
        // findings
        payload.additionalInfo = { findingId: "" };
        payload.additionalInfo.findingId = payload.findingId;
      } else if (type == 3) {
        // files
        payload.additionalInfo = { evidence: "" };
        payload.additionalInfo.evidence = payload.filename;
      } else if (type == 4) {
        // statusCode
        payload.additionalInfo = { previousStatus: "", updatedStatus: "" };
        payload.additionalInfo.previousStatus =
          this.presentTaskObject.statusCode;
        payload.additionalInfo.updatedStatus = payload.statusCode;
      } else if (type == 5) {
        // results
        payload.additionalInfo = { actualOutput: "", totalPopulation: '' };
        payload.additionalInfo.resultfor = payload.resultfor
        payload.additionalInfo.kpiName = payload.description;
        payload.additionalInfo.actualOutput = payload.actualOutput;
        payload.additionalInfo.totalPopulation = payload.totalPopulation;
      } else if (type == 6) {
        // assignee
        payload.additionalInfo = { assignee: "" };
        payload.additionalInfo.assignee = payload.assignee;
      } else if (type == 7) {
        // controlResults
        payload.additionalInfo = { actualOutput: "", totalPopulation: '' };
        payload.additionalInfo.resultfor = payload.resultfor
        payload.additionalInfo.controlorKpi = payload.controlorKpi;
        payload.additionalInfo.controlNumber = payload.controlNumber;
        payload.additionalInfo.actualOutput = payload.actualOutput;
        payload.additionalInfo.totalPopulation = payload.totalPopulation;
      }
      try {
        await this.$http
          .post(
            `${process.env.VUE_APP_MONITOR_API_URL}/task/${this.$route.params.taskId}/type/${type}/update`,
            payload
          )
          .then(async (result: any) => {
            toast.info(`Updated`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
            await this.getPresentComplianceObject();
            await this.getTasksForCompliance(
              this.$route.params.complianceId,
              this.$route.params.taskId
            );
            await this.getTaskHistoryObject();
            await this.getSingleComplianceFindings();
          });
      } catch {
        toast.error(`error`, {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }finally {
				this.clickOnButtonForFinding = false;
        this.clickOnButtonForResult = false;
			}
    },
    async getAllTeams() {
      this.teamObject = [];
      await this.$http
        .get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`)
        .then((res: any) => {
          this.users = res.data;
        });
      for (var i = 0; i < this.users.length; i++) {
        this.teamObject.push({
          label: this.users[i].teamName,
          value: this.users[i]._id,
        });
      }
    },
    async getConvertDataForFile(data: any) {
      return new Promise((resolve) => {
        const reader: any = new FileReader();
        reader.onload = function () {
          resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
        };
        reader.readAsDataURL(data);
      });
    },
    async getallUsers() {
      await this.$http
        .get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`)
        .then((res: any) => {
          this.orgUsersList = res.data;
          this.orgUsersList.map((obj:any)=>{
            this.optionsUsersList.push({value : obj._id , label : obj.name});
            
            
            // let newObj = obj
          })
          for (var i = 0; i < this.orgUsersList.length; i++) {
            // if (this.userInfo._id !== this.orgUsersList[i]._id) {
            //   this.optionsUsersList.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
            // }
            // if (this.orgUsersList[i].isActive == true) {
              this.optionsUserArray.push({
                label: this.orgUsersList[i].name,
                value: this.orgUsersList[i]._id,
              });
            // }
          }
        });
    },
    async getPresentComplianceObject() {
      await this.$http
        .get(
          `${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/${this.$route.params.complianceId}/get`
        )
        .then((result: { data: any }) => {
          this.presentComplianceObject = result.data;
        })
        .catch((error: any) => {
          toast.error(`error`, {
            timeout: 1000,
            closeOnClick: true,
            closeButton: "button",
            icon: true,
          });
        });
    },
    async getSingleComplianceFindings(cId:any) {
        this.findingsForCompliance = [];
        // let eachEfindingObj: any = [];
        await this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/${this.$route.params.complianceId}/findings/getall`).then(async (result: any) => {
          result.data.forEach((finding: any)=>{
            if(finding.taskId == this.$route.params.taskId){
              // eachEfindingObj = finding
              this.findingsForCompliance.push(finding)
            }
          })
        });
    },
    async getTasksForCompliance(cId: any, tId: any) {
      this.tasksForCompliance = [];
      this.resultsForCompliance = [];
      this.controlResultsForCompliance = [];
      try {
        await this.$http
          .get(
            `${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/${cId}/tasks/get`
          )
          .then((result: any) => {
            this.tasksForCompliance = result.data;
            let present = result.data.find((obj: any) => {
              return obj._id == tId;
            });
            this.presentTaskObject = { ...present };
            this.kpisObject = this.presentTaskObject.kpis || [] ;
            this.TaskDetailsDropDown.taskStatus = this.presentTaskObject.statusCode;
            let teamLeaderObj: any 
            if(Object.keys(this.presentTaskObject).includes('assignee') && this.presentTaskObject.assignee !== undefined){
              teamLeaderObj = this.optionsUserArray.find((obj: any) => {
                return obj.value == this.presentTaskObject.assignee;
              });
            } else{
              teamLeaderObj = this.optionsUserArray.find((obj: any) => {
                return obj.value == this.presentComplianceObject.teamLeader;
              });
            }
            this.presentComplianceObject.teamLeader = teamLeaderObj;
            if(teamLeaderObj != undefined || teamLeaderObj != null || teamLeaderObj != ''){
              this.teamLeaderValue = teamLeaderObj.value
            }
            if(Object.keys(this.presentTaskObject).includes('comments') && this.presentTaskObject.comments.length != 0){
              // const decodedComment = Buffer.from(obj.comment, 'base64').toString('utf-8');
              let commentsArray: any = [];
              this.presentTaskObject.comments.forEach((commentObj: any)=>{
                commentObj.comment = atob(commentObj.comment)

                commentsArray.push(commentObj);
              })
              this.presentTaskObject.comments = commentsArray
              this.presentTaskObject.comments = this.presentTaskObject.comments.sort().reverse();
            }
            if(Object.keys(this.presentTaskObject).includes('results') && this.presentTaskObject.results.length !== 0){
              let resultObj: any = {};
              // let allPerformanceOutputs: any = []
              let populationValue
              let compliantValue
              let resultfor
              let controlorKpi
              this.presentTaskObject.results.forEach((eachResult:any)=>{
                let allPerformanceOutputs: any = []
                resultfor = eachResult.resultfor
                controlorKpi = eachResult.controlorKpi
                populationValue = 0;
                compliantValue = 0;
                populationValue = eachResult.totalPopulation
                compliantValue = eachResult.actualOutput
                let performanceOutput = eachResult.totalPopulation && eachResult.actualOutput ? (eachResult.actualOutput / eachResult.totalPopulation)* 100 : 0;
                allPerformanceOutputs.push(Math.ceil(performanceOutput))
                resultObj.comments = eachResult.comments
                this.kpiProgress = 0;
                if(compliantValue != populationValue){
                  // this.kpiProgress = allPerformanceOutputs.length!==0 ? allPerformanceOutputs.performanceOutput: 0
                  this.kpiProgress = allPerformanceOutputs.length!==0 ? Math.ceil(allPerformanceOutputs.reduce((acc: any, currentValue: any) => acc + Number(currentValue))/allPerformanceOutputs.length) : 0;
                } else{
                  this.kpiProgress = 100
                }
                eachResult.kpiProgress = this.kpiProgress
                this.severity = this.riskSeverity(Math.round(this.kpiProgress/4));
                eachResult.resultfor = resultfor
                eachResult.controlorKpi = controlorKpi
                eachResult.totalPopulation = populationValue
                eachResult.actualOutput = compliantValue
                eachResult.severity = this.severity
                
              })
              resultObj.resultfor = resultfor
              resultObj.controlorKpi = controlorKpi
              resultObj.totalPopulation = populationValue
              resultObj.actualOutput = compliantValue
              resultObj.severity = this.severity
              this.resultsForCompliance.push(resultObj)
              // resultObj.performanceOutput = this.kpiProgress
              
            }
            if(Object.keys(this.presentTaskObject).includes('controlResults') && this.presentTaskObject.controlResults.length !== 0){
              let resultObj: any = {};
              let allPerformanceOutputs: any = []
              let populationValue
              let compliantValue
              let resultfor
              let controlNumber
              this.presentTaskObject.controlResults.forEach((eachResult:any)=>{
                
                resultfor = eachResult.resultfor
                controlNumber = this.presentTaskObject.clientUniqueId
                populationValue = eachResult.totalPopulation
                compliantValue = eachResult.actualOutput
                eachResult['performanceOutput'] = eachResult.totalPopulation && eachResult.actualOutput ? (eachResult.actualOutput / eachResult.totalPopulation)* 100 : 0;
                allPerformanceOutputs.push(Math.ceil(eachResult['performanceOutput']))
                resultObj.comments = eachResult.comments
              })
              if(compliantValue != populationValue){
                // this.progress = allPerformanceOutputs.length!==0 ? allPerformanceOutputs.performanceOutput: 0
                this.controlProgress = allPerformanceOutputs.length!==0 ? Math.ceil(allPerformanceOutputs.reduce((acc: any, currentValue: any) => acc + Number(currentValue))/allPerformanceOutputs.length) : 0;
              } else{
                this.controlProgress = 100
                // this.progress = 100
              }
              this.severity = this.riskSeverity(Math.round(this.controlProgress/4));
              resultObj.resultfor = resultfor
              resultObj.controlorKpi = controlNumber
              resultObj.totalPopulation = populationValue
              resultObj.actualOutput = compliantValue
              resultObj.severity = this.severity
              resultObj.controlProgress = this.controlProgress
              this.controlResultsForCompliance.push(resultObj)
            }
            this.controlsArray = this.presentTaskObject.frameworks.filter((obj : any)=>{
                return this.presentComplianceObject.frameworks.includes(obj.framework)
            })
            this.controlsArray.forEach((item: any) => {
                this.controlDetailsArray = item.controls.map((controlNumber: any) => this.getControlDetails(controlNumber)).filter(Boolean);
                item.controlsDetailsArray = this.controlDetailsArray;
                delete item.controls;
            });

            this.evidenceArray = []
              if(this.presentTaskObject.files){
                this.presentTaskObject.files.forEach((fileObj: any) => {
                  this.evidenceArray.push(fileObj.filename)
                });
              }
          });
      } catch {
        // toast.error(`error 44`, {
        //   timeout: 1000,
        //   closeOnClick: true,
        //   closeButton: "button",
        //   icon: true,
        // });
      }
    },
    getControlDetails(controlNumber: any) {
      const control = this.allFrameWorkControls.find(
        (item: any) => item.controlNumber === controlNumber
      );
      return control
        ? {
            controlNumber: control.controlNumber,
            description: control.controlAuditMethodology,
          }
        : null;
    },
    // /api/org/controls/:controlId/documents/get
    // async getControlDocuments() {
    //   await this.$http
    //     .get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/:controlId/documents/get`)
    //     .then((result: { data: any }) => {
    //       this.eachControlDocuments = result.data;
    //     })
    //     .catch((error: any) => {
    //       toast.error(`error`, {
    //         timeout: 1000,
    //         closeOnClick: true,
    //         closeButton: "button",
    //         icon: true,
    //       });
    //     });
    // },
    async getControls() {
      await this.$http
        .get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`)
        .then((result: { data: any }) => {
          this.allFrameWorkControls = result.data;
          const groupedControlsFrameworks = groupBy(
            result.data,
            "controlFrameWork"
          );
          const listOfFrameworks = Object.keys(groupedControlsFrameworks);
          listOfFrameworks.forEach((key) => {
            this.complianceFrameworkArray.push(key);
          });
        })
        .catch((error: any) => {
          toast.error(`error`, {
            timeout: 1000,
            closeOnClick: true,
            closeButton: "button",
            icon: true,
          });
        });
    },
    onCancelbtn() {
      this.v$.$reset();
      (this.findingsObject.findingEvidence = ""),
        // (this.findingsObject.findingCategory = ""),
        (this.findingsObject.finding = "");
    },
    onCancelbtnForResult() {
      this.v$.$reset();
      this.resultObject = {...this.resultObjectDummy}
      this.resultOfPopulation= true;
      this.resultOfNegativeOutput= true;
      this.resultOfNegativePopulation= true;
      this.conditionForControlOrKPI = false;
    },
    riskSeverity(value: number) {
        if (value <= 3) {
            return 1;
        } else if (value > 3 && value <= 8) {
            return 2;
        } else if (value > 8 && value <= 12) {
            return 3;
        } else if (value > 12 && value <= 16) {
            return 4;
        } else if (value > 16 && value <= 25) {
            return 5;
        }
    },
    // riskSeverity(value: number) {
    //     if (value <= 20) {
    //         return 1;
    //     } else if (value > 20 && value <= 40) {
    //         return 2;
    //     } else if (value > 40 && value <= 60) {
    //         return 3;
    //     } else if (value > 60 && value <= 80) {
    //         return 4;
    //     } else if (value > 80 && value <= 100) {
    //         return 5;
    //     }
    // },
    async addFindingButton() {
      this.v$.$touch();
      if (!this.v$.findingsObject.$invalid) {
        this.clickOnButtonForFinding = true;
        let payload = this.findingsObject;
        await this.updateComplianceTask(2, payload);
        this.showPopUp = false;
        this.onCancelbtn();
      }
    },
    // validateResultObject() {
    //   // Ensure the fields are not empty
    //   if (!this.resultObject.actualOutput || !this.resultObject.totalPopulation) {
    //     alert('Both Actual Output and Total Population are required.');
    //     toast.error(`Both Actual Output and Total Population are required`, {
    //       timeout: 1000,
    //       closeOnClick: true,
    //       closeButton: "button",
    //       icon: true,
    //     });
    //     return false;
    //   }
      
    //   // Ensure the values are non-negative
    //   if (parseFloat(this.resultObject.actualOutput) < 0 || parseFloat(this.resultObject.totalPopulation) < 0) {
    //     // alert('Values must be non-negative.');
    //     toast.error(`Values must be non-negative`, {
    //       timeout: 1000,
    //       closeOnClick: true,
    //       closeButton: "button",
    //       icon: true,
    //     });
    //     return false;
    //   }
      
    //   // Ensure Total Population is greater than or equal to Actual Output
    //   if (parseFloat(this.resultObject.totalPopulation) < parseFloat(this.resultObject.actualOutput)) {
    //     // alert('Total Population must be greater than or equal to Actual Output.');
    //     toast.error(`Total Population must be greater than or equal to Actual Output`, {
    //       timeout: 1000,
    //       closeOnClick: true,
    //       closeButton: "button",
    //       icon: true,
    //     });
    //     return false;
    //   }

    //   // If Total Population is equal to Actual Output, set progress to 100
    //   if (parseFloat(this.resultObject.totalPopulation) === parseFloat(this.resultObject.actualOutput)) {
    //     this.progress = 100;
    //   }

    //   return true;
    // },
    // async addResultButtonForTask() {
    //   // Perform validation
    //   const isValid = this.validateResultObject();
    //   if (!isValid) return;

    //   // Proceed with the rest of the logic
    //   this.showPopUpForResult = false;
    //   let payload = this.resultObject;
    //   await this.updateComplianceTask(5, payload);
    //   this.resultObject.actualOutput = "";
    //   this.resultObject.totalPopulation = "";
    //   this.resultObject.comments = "";
    // }
    // async addResultButtonForTask(data:any) {
    //   this.v$.$touch();
    //   // parseFloat(this.resultObject.totalPopulation) === parseFloat(this.resultObject.actualOutput)
    //   if(parseFloat(data.totalPopulation) >= parseFloat(data.actualOutput)){
    //     if (!this.v$.resultObject.$invalid) {
    //       // if (parseFloat(this.resultObject.totalPopulation) === parseFloat(this.resultObject.actualOutput)) {
    //       //   this.progress = 100;
    //       // }
    //       this.showPopUpForResult = false;
    //       let payload = this.resultObject;
    //       await this.updateComplianceTask(5, payload);
    //       this.resultObject.actualOutput = "";
    //       this.resultObject.totalPopulation = "";
    //       this.resultObject.comments = "";
    //       this.v$.$reset()
    //       // this.onCancelbtnForResult();
    //     }
    //   } else {
    //     this.greaterThanOrEqualActual = true
    //     // toast.error(`Population must be greater than Compliant`, {
    //     //     timeout: 6000,
    //     //     closeOnClick: true,
    //     //     closeButton: "button",
    //     //     icon: true,
    //     //   });
    //   }
    // },
    // changingResultObject(value: any){
    //   this.isTotalPopulationValid()
    //   this.isNonNegative(value);
    // },
    isNonNegative(value: any) {
      return parseFloat(value) >= 0;
    },
    isTotalPopulationValid() {
      this.resultOfPopulation = parseFloat(this.resultObject.totalPopulation) >= parseFloat(this.resultObject.actualOutput);
      return parseFloat(this.resultObject.totalPopulation) >= parseFloat(this.resultObject.actualOutput);
    },
    validateResultObject() {
      const { actualOutput, totalPopulation } = this.resultObject;
      const isNonNegativeOutput = this.isNonNegative(actualOutput);
      this.resultOfNegativeOutput = isNonNegativeOutput;
      const isNonNegativePopulation = this.isNonNegative(totalPopulation);
      this.resultOfNegativePopulation = isNonNegativePopulation;
      const isTotalPopValid = this.isTotalPopulationValid();
      return isNonNegativeOutput && isNonNegativePopulation && isTotalPopValid;
    },
    async updateTaskForTeamLeader(assignee: any){
      let payload: any = { assignee: assignee.value };
      await this.updateComplianceTask(6, payload);
       if(this.presentComplianceObject.teamLeader != undefined){
          this.newTeamLeader = this.presentComplianceObject.teamLeader
        }
    },
    async addResultButtonForTask() {
      this.v$.$touch();
      
      // Perform custom validation
      const isValid = this.validateResultObject();
      this.clickOnButtonForResult = true;
      if (!isValid || this.resultObject.comments == "") return;

      // Proceed with the rest of the logic
      this.showPopUpForResult = false;
      this.conditionForControlOrKPI = false;
      let payload = this.resultObject;
      // if (parseFloat(this.resultObject.totalPopulation) === parseFloat(this.resultObject.actualOutput)) {
      //   this.progress = 100;
      // }
      if (payload.resultfor === 2 && !this.storingKpiIds.includes(payload.controlorKpi)) {
        this.storingKpiIds.push(payload.controlorKpi);
      }
      if(payload.resultfor == 1){
        await this.updateComplianceTask(7, payload);
      }else if(payload.resultfor == 2){
        await this.updateComplianceTask(5, payload);
      }
      
      
      this.resultObject.resultfor = ""
      this.resultObject.controlorKpi = ""
      this.resultObject.actualOutput = "";
      this.resultObject.totalPopulation = "";
      this.resultObject.comments = "";
      this.v$.$reset();
    }
  
  },
});
</script>