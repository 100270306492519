<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="items-center justify-between flex">
			<div class="title flex items-center">
				<h1>{{presentComplianceObject.title}}</h1>
			</div>
            <div class="flex">
                 <button class="mr-3 flex h-8" v-if="selectedTabIndex == 3 "  @click="filterOpenForAuditInfo" :class="teamsArray.length == 0 ? 'filterButtondisabled pointer-events-none' : 'filterButton'">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
                 <button class="mr-3 flex h-8" v-if="selectedTabIndex == 4"  @click="filterOpenForFindings" :class="tableRow.length == 0 ? 'filterButtondisabled pointer-events-none' : 'filterButton'">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
                <button @click="startPresentCompliance()" class="btnprimary mr-3 items-center">
                    <div v-if="presentComplianceObject.statusCode == 10401">Start</div>
                    <div v-else>Tasks</div>
                </button>
                <button @click="onclickTerminate()" v-if="presentComplianceObject.statusCode != 10418 && tasksOpen" :class="tasksOpen == true ? 'btnprimary':'btndisabled'" class="btnprimary items-center">
                    <div>Terminate</div>
                </button>
                <!-- <button class="btnprimary mr-3 items-center" @click="closeAudit()" :class="tasksOpen == false ? 'btnprimary':'btndisabled'">
                    Close
                </button> -->
            </div>
		</div>
        <div class="mainContainer tabStructure  w-full bg-white border rounded-sm mt-5 px-5 pt-5 pb-3 border-lightgrey">
            <div class="pr-5">
                    <div class="tabStructureDiv">
                        <div class="flex flex-row w-1/2 borderBottom justify-between cursor-pointer tab-container">
                        <div class="pb-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold text-sm primary':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in tabHead" :key="tabIndex" @click="toggleTabs(tab)">
                                <div :class="{ 'anime-left': !sideButton, 'anime-right': sideButton }">
                                    <div class="cursor-pointer">{{ tab.displayName }}</div>
                                    <div :class="{ progress: selectedTabIndex == tab.id }" class="h-1 p-0.75"></div>
                                </div>
                            </div>
                        </div>
                        <div class="progressbarB rounded h-1"></div>
                    </div>
                    <div class="h-full">
                        <div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 1">
                            <div class="lg:flex">
                            <div class="lg:w-1/2">
                                <div v-if="series.length > 0" class="w-full h-full flex justify-center items-center">
                                    <div v-if="presentComplianceObject.statusCode != 10401" id="chart">
                                        <apexchart type="pie" width="380" :options="chartOptions" @dataPointSelection="clickOnPieLabel" :series="series"></apexchart>
                                    </div>
                                    <div v-if="presentComplianceObject.statusCode == 10401">
                                        <p class="text-xs font-normal">Compliance Not Started Yet</p>
                                    </div>
                                </div>
                            </div>
                            <div class="lg:w-1/2 topPaddingForTable ">
                                <!-- <p class="text-base font-semibold text-center mt-2">jsdjksdh</p>  -->
                                    <div class="tableCornerRadius w-85">
                                        <table>
                                            <thead>
                                                <th scope="col" v-for="column in headers" :key="column" :class="getColumnHeadWidth(column)" class="bg-white th1 cursor-pointer capitalize">
                                                    <div v-if="column == 'taskStatus'">
                                                        <div>{{''}}</div>
                                                    </div>
                                                    <div v-else class="flex">
                                                    
                                                        <div >{{ column }}</div>
                                                        <!-- <div @click="sort(column)" v-if="!isColumnFrameWork(column)" class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>  -->
                                                    </div>
                                                </th>
                                                
                                            </thead>
                                            <tbody>
                                                <tr v-for="(row, index) in viewComplianceObj" :key="index" class="rowHeight">
                                                    <th v-for="presentColumn in headers" :key="presentColumn" class="cursor-pointer relative">
                                                        
                                                        <div v-if="presentColumn == 'status'">
                                                            <div :class="textColor(row['taskStatus'])">
                                                                {{
                                                                    taskStatusCodes.find((l)=>{
                                                                        return  l.id == row['taskStatus']
                                                                    }).value
                                                                }}
                                                            </div>
                                                            <!-- {{row['taskStatus']}}  -->
                                                        </div>
                                                        <div v-else>
                                                            <div class="table-ellipsis">
                                                                <div :class="presentColumn == 'tasks'?'pl-3':''">
                                                                    {{ row[presentColumn] }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-xs flex pt-2 mb-4 flex items-center justify-center"><p class="font-semibold text-darkred">*Note : </p> To close the Compliance All Tasks should be in completed state.</div>
                                </div>
                            </div>
                            
                    </div>
                        <div class="overflow-y-scroll descriptionToggle mt-2" v-if="selectedTabObj.id == 2">  
                            <div>
                                <!-- <div class="p-2">
                                    <div class="w-full flex flex-row">
                                        <div class="mb-2 w-1/2 mr-2">
                                            <label class="controllabel">Audit Title</label>
                                            <div class="inputboxstyling">{{this.presentComplianceObject.title}}</div>
                                        </div>
                                        <div class="mb-2 w-1/2 ml-2">
                                            <label class="controllabel">Audit ID</label>
                                            <div class="inputboxstyling">{{this.presentComplianceObject.complianceId}}</div>
                                        </div>
                                    </div>
                                    <div class="w-full flex flex-row">
                                        <div class="mb-2 w-1/2 mr-2">
                                            <label class="controllabel">Audit Period</label>
                                            <div class="inputboxstyling">{{auditPeriod}}</div>
                                        </div>
                                        <div class="mb-2 w-1/2 ml-2">
                                            <label class="controllabel">Audit Start & EndDate</label>
                                            <div class="inputboxstyling">{{startDate}} - {{endDate}}</div>
                                        </div>
                                    </div>
                                    <div class="w-full flex flex-row">
                                        <div class="mb-2 w-1/2 mr-2">
                                            <label class="controllabel">Audit Team Leader</label>
                                           
                                            <Multiselect
                                                v-model="presentComplianceObject.teamLeader"
                                                :options="optionsUserArray"
                                                :multiple="false"
                                                :close-on-select="true"
                                                :disabled="true"
                                                :clear-on-select="true"
                                                label="label"
                                                track-by="value"    
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="TeamLeader"
                                                deselectLabel=""
                                                selectLabel=""
                                                class="custom-multiselect"
                                            >
                                            </Multiselect>
                                        </div>
                                        <div class="w-1/2 ml-2">
                                            <label class="controllabel">Frameworks</label>
                                                
                                                    <multiselect 
                                                        v-model="presentComplianceObject.frameworks" 
                                                        :multiple="true" 
                                                        :max="3" 
                                                        :options="frameworks" 
                                                        :disabled="true"
                                                        placeholder="Frameworks"
                                                        class="custom-multiselect"
                                                    >
                                                    </multiselect>
                                        </div> 
                                    </div> 
                                    </div> -->
                            <div>

                        <div>  
                            <div class="flex flex-row"> 

                          
                            <div class="w-6/12">
                        <label class="font-extrabold text-xs">Compliance period</label>
                        <br>
                        <p class="text-sm mt-2" style="display:inline-block;">{{changeDateFormat(presentComplianceObject.period.fromDate) != undefined ?  changeDateFormat(presentComplianceObject.period.fromDate) : 'N/A'}} </p>
                        -
                        <p class="text-sm" style="display:inline-block;">{{changeDateFormat(presentComplianceObject.period.toDate) != undefined ?  changeDateFormat(presentComplianceObject.period.toDate) : 'N/A'}}</p>

                            </div>  
                    </div>  
						
                        </div>  
                        
                        <div class="mt-3">
                            <div class="flex flex-row">
                                <div class="w-6/12">
                                    <label class="font-extrabold text-xs">Compliance Start Date</label>
                                    <p class="text-sm mt-2">{{changeDateFormat(presentComplianceObject.startDate) != undefined ? changeDateFormat(presentComplianceObject.startDate) : 'N/A'}}</p>
                                </div>
                                <div class="w-6/12">
                                   <label class="font-extrabold text-xs">Compliance End Date</label>
                                    <p class="text-sm mt-2">{{changeDateFormat(presentComplianceObject.endDate) != undefined ? changeDateFormat(presentComplianceObject.endDate) : 'N/A'}}</p>
                                </div>
                                
                            </div>
                        </div>
                        <div class="mt-3">
                             <div class="flex flex-row">
                                <div class="w-6/12">
                                    <label class="font-extrabold text-xs">Compliance Title</label>
                                    <p class="text-sm mt-2">{{presentComplianceObject.title != undefined ? presentComplianceObject.title : 'N/A'}}</p>
                                </div>
                                <div class="w-3/6">
                                    <label class="font-extrabold text-xs">Compliance Id</label>
                                    <p class="text-sm mt-2">{{presentComplianceObject.complianceId != undefined ? presentComplianceObject.complianceId : 'N/A'}}</p>
                                </div>
                                <!-- <div class="w-3/6 leftspacing">
                                    <label class="font-extrabold text-xs">Team Leader</label>
                                    <p>{{presentComplianceObject.teamLeader.label}}</p>
                                </div> -->
                            </div>
                        </div>
                        <div class="mt-3">
                             <div class="flex flex-row">
                                <!-- <div class="w-6/12">
                                    <label class="font-extrabold text-xs">Compliance Title</label>
                                    <p>{{presentComplianceObject.title}}</p>
                                </div> -->
                                <div class="w-1/2">
                                    <label class="font-extrabold text-xs">Team Leader</label>
                                    <!-- <p class="text-sm mt-2">{{teamLeaderLabel != undefined ? teamLeaderLabel : 'N/A'}}</p> -->
                                    <div class="text-sm flex mt-2 flex-wrap">
                                        <div v-if="teamLeaderLabel == '' || teamLeaderLabel == undefined || teamLeaderLabel == null">N/A</div>
                                        <div v-else class="h-6 bg-primary mr-3 px-2 rounded-sm flex items-center text-white text-sm">{{teamLeaderLabel}}</div>
                                    </div>
                                </div>
                                <div class="w-1/2">
                                    <label class="font-extrabold text-xs">Frameworks (Maximum 3 Frameworks )</label>
                                    <div class="text-sm flex mt-2 flex-wrap">
                                        <div v-if="presentComplianceObject.frameworks == '' || presentComplianceObject.frameworks == [] || presentComplianceObject.frameworks == undefined || presentComplianceObject.frameworks == null">N/A</div>
                                        <div class="w-auto h-6 bg-primary mr-3 px-2 rounded-sm flex items-center text-white text-sm" v-for="eachFramework in presentComplianceObject.frameworks" :key="eachFramework">
                                            {{eachFramework}}
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="w-3/6 leftspacing">
                                    <label class="font-extrabold text-xs">Compliance Members</label>
                                    <div class="text-sm flex mt-2 flex-wrap">
                                        <div class="w-auto h-6 bg-primary mr-3 px-2 rounded-sm flex items-center text-white text-sm" v-for="complianceMember in presentComplianceObject.complianceMembers" :key="complianceMember">
                                            {{
                                                optionsUserArray.find((eachUser)=>{
                                                    return eachUser.value == complianceMember
                                                }).label
                                            }}
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="">
                            <div class="flex flex-row mt-3">
                                <div class="w-6/12">
                                    <div>
                                        <label class="font-extrabold text-xs">Location</label>
                                        <div class="text-sm flex mt-2 flex-wrap">
                                            <div v-if="presentComplianceObject.location == '' || presentComplianceObject.location == [] || presentComplianceObject.location == undefined || presentComplianceObject.location == null">N/A</div>
                                            <div v-else class="w-auto h-6 bg-primary mb-2 mr-3 px-2 rounded-sm flex items-center text-white text-sm" v-for="location in presentComplianceObject.location" :key="location">
                                                {{
                                                    locationObject.find((eachLocation)=>{
                                                    return eachLocation.value == location
                                                }).label
                                                }}
                                            </div>
                                            <!-- <div v-for="location in presentComplianceObject.location" :key="location" class="pt-2">
                                                <div class="w-auto h-6 bg-primary mr-3 px-2 rounded-sm flex items-center text-white text-sm ">  {{location}} </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="w-6/12">
                                    <label class="font-extrabold text-xs">Compliance Members</label>
                                    <div class="text-sm flex mt-2 flex-wrap">
                                        <div v-if="presentComplianceObject.complianceMembers == '' || presentComplianceObject.complianceMembers == [] || presentComplianceObject.complianceMembers == undefined || presentComplianceObject.complianceMembers == null">N/A</div>
                                        <div v-else class="w-auto h-6 bg-primary mb-2 mr-3 px-2 rounded-sm flex items-center text-white text-sm" v-for="complianceMember in presentComplianceObject.complianceMembers" :key="complianceMember">
                                            {{
                                                optionsUserArray.find((eachUser)=>{
                                                     return eachUser.value == complianceMember
                                                }).label
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                        </div>
                        <div class="overflow-y-scroll descriptionToggle mt-4" v-if="selectedTabObj.id == 3">
                            <div class="">
                                <tableData :tableRowData="teamsArray" :headersData="Headers" :departmentArr="teamObject" :locations="locationObject" :loadData="dataLoading"></tableData>
                                <div v-if="toolTipActive">
                                    <tool-tip :tipText="toolTipText"></tool-tip>
                                </div>
                            </div>
                        </div>
                        <div class="overflow-y-scroll descriptionToggle mt-4" v-if="selectedTabObj.id == 4">
                            <div >
                                <tableData :tableRowData="tableRow" :headersData="mainHeaders" :departmentArr="teamObject" :locations="locationObject" :loadData="dataLoading"></tableData>
                                <div v-if="toolTipActive">
                                    <tool-tip :tipText="toolTipText"></tool-tip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <popup v-if="showFindingsPopUp" :popUp="showFindingsPopUp">
            <template v-slot:header>
                <div class="flex items-center justify-between m-4 rounded">
                    <h1>Findings</h1>
                    <button class="float-right text-3xl" @click="onCancelButtonAddPopUp()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div class="statuscontainer popupbodycontainer">
                    <div class="formcontainer flex flex-col">
                        <div class="row flex flex-row w-full upperspacing">
                            <div class="w-6/12 pr-2">
                                <label class="controllabel">Finding-Id</label><br/>
                                <p class="mt-2 text-sm">{{ActionObj.findingId}}</p>
                            </div>
                            <!-- <div class="w-6/12 pr-2">
                                <label class="controllabel">Finding Category</label><br/>
                                <p class="mt-2">{{getFindingCategory(ActionObj.findingCategory)}}</p>
                            </div> -->
                        </div>
                        <!-- <div class="row flex flex-row w-full upperspacing">
                            <div class="w-1/2 pr-2">
                                <label class="controllabel">CCID</label><br/>
                                <p class="mt-2">{{ActionObj.ccid}}</p>
                            </div>
                            <div class="w-1/2 pr-2">
                                <label class="controllabel">KPI Id</label><br/>
                                <p class="mt-2">{{ActionObj.kpi_id}}</p>
                            </div>
                        </div> -->
                        <div class="w-full mt-3">
                            <label class="controllabel">Findings</label><br/>
                            <p class="mt-2 text-sm">{{ActionObj.finding}}</p>
                        </div>
                        <div class="w-full mt-3">
                            <label class="controllabel">Finding related to Evidence</label><br/>
                            <p class="mt-2 text-sm">{{ActionObj.findingEvidence}}</p>
                        </div>
                    </div>
                </div>
            </fieldset>
        </popup>

        <popup v-if="showTerminatePopup" :popUp="showTerminatePopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Terminate - {{presentComplianceObject.title}}</h1>
                    <button class="float-right text-3xl" @click="onCancelTerminate()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <div class="popup-business">
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full px-1">
                                        <label for="number" class="controllabel">Reason/Justification for Terminating the Compliance</label>
                                        <textarea class="inputboxstyling resize-none mt-2" v-model="terminateComments" placeholder="Reason/Justification for Terminating the Compliance" rows="3" ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row flex flex-row w-full upperspacing">
                                <div class="w-full px-1 flex">
                                    <input type="checkbox" class="checkbox cursor-pointer mr-2" v-model="retainFindings" id="retainFindings"/> 
                                    <label for="retainFindings" class="controllabel">Retain Findings</label>
                                </div>
                            </div>
                            <div class="formcontainer flex justify-center mt-2">
                                <button @click="TerminatePresentCompliance()" :class="terminateComments =='' ? 'btndisabled pointer-events-none': 'btnprimary'">Terminate</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>
    </div>
</template>


<style scoped>
.primary{
	color:#152a43;
}
.btndisabled {
 
  @apply bg-lightgrey font-opensans font-bold text-sm px-2.5 py-2.5 text-gray leading-3 rounded
}
th{
    height: 40px !important;
}
.rowHeight {
    height: 20px !important;
}
.tableCornerRadius{
    margin: 0 ;
}
.multiselect__select {
    display: none !important;
}
.topPaddingForTable{
    padding-top : 22px;
}
.progress {
	background: #152a43;
	position: relative;
	border-radius: 4px;
	z-index: 2;
}
.progressbarB {
	border: 2px solid #e9e9e9;
	background: #e9e9e9;
	position: relative;
	top: -8px;
	z-index: 1;
}
.descriptionToggle::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}

.descriptionToggle::-webkit-scrollbar-thumb {
	background: transparent;
}
.anime-left {
    animation-name: moveLeft;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
.anime-right {
    animation-name: moveRight;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
.tabStructure{
    min-height: 423px !important;
}
</style>

<script lang="ts">

import { toast } from '@/main';
import breadCrumb from '@/components/breadCrumb.vue';
import Vue, { defineComponent } from 'vue';
import tableData from '@/components/tableData.vue';
import Multiselect from 'vue-multiselect';
import { emitter } from '@/main';
import popup from '@/components/popup.vue';
import { mapGetters } from 'vuex';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
    data(): any{
        return{
        //     series: [44, 55, 13, 43, 22],
        //     chartOptions: {
        //         chart: {
        //         width: 380,
        //         type: 'pie',
        //         },
        //     labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
        //     responsive: [{
        //       breakpoint: 480,
        //       options: {
        //         chart: {
        //           width: 200
        //         },
        //         // legend: {
        //         //   position: 'bottom'
        //         // }
        //       }
        //     }]
        //   },
            headers: ['status','tasks'],
            Headers: ['Team','Entity','Team Owner','Reviewer'],
            series: [],
            chartOptions: {
                chart: {
                    type: 'pie',
                },
                colors: ['#a4a4a4', '#FF9900', '#8600F0', '#152a43', '#00B050','#C00000', '#C00000'],
                labels: ['Not Started', 'In Review', 'Pending Evidence', 'Evidence Uploaded', 'Completed','Not Applicable', 'Terminated'],
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false,
                    position: 'bottom',
                    horizontalAlign: 'left',
                    markers: {
                        width: 9, height: 9
                    },              
                    itemMargin: {
                        horizontal:10, vertical: 0
                    },
                },
            },
            auditorAndAuditeeInfo:[],
            allEntities: [],
            viewComplianceObj: [],
            routesArray:[],
            navIndexNums: { name: '', index: '' },
            showFiltersForFindings : false,
            showFiltersForAuditInfo : false,
            taskStatusCodes: [
				{
					id: 10401,
					value: 'Not Started'
				},
                {
					id: 10415,
					value: 'In Review'
				},
				{
					id: 10414,
					value: 'Pending Evidence'
				},
                {
					id: 10416,
					value: 'Evidence Uploaded'
				},
                {
					id: 10403,
					value: 'Completed'
				},
                {
					id: 10417,
					value: 'Not Applicable'
				},
                {
					id: 10418,
					value: 'Terminated'
				}
			],
            frameworks:[],
            presentComplianceObject:{},
            findingsForCompliance: [],
            tasksOpen:false,
            tasksForCompliance:[],
            startDate:'',
            endDate:'',
            auditPeriod:'',
            teamsArray:[],
            optionsUserArray:[],
            orgUsersList:[],
            selectedTabObj:{},
            selectedTabIndex: 1,
            tabHead: [
				{ displayName: 'Task Analytics', name: 'Task Analytics' , id: 1 },

				{ displayName: 'Properties', name: 'Properties', id: 2 },

				{ displayName: 'Reviewer Info', name: 'Reviewer Info', id: 3 },

				{ displayName: 'Findings', name: 'Findings', id: 4 },
			],
            sideButton: false,
            teamObject:[],
            teamsData:[],
            locationObject:[],
            tableRow : [],
            ActionObj : {},
            showFindingsPopUp : false,
            findingCategoryData: [
				{
					id: 1,
					value: 'Minor'
				},
				{
					id: 2,
					value: 'Major'
				},
				{
					id: 3,
					value: 'OFI'
				},
				{
					id: 4,
					value: 'Observation'
				}
			],
            showTerminatePopup:false,
            terminateComments:'',
            retainFindings: false,
            entityNameForTable : '',
            teamLeaderLabel : '',
            toolTipActive:false,
			toolTipText:'',
            dataLoading:true,
        }
    },
    components:{
        breadCrumb,
        tableData,
        Multiselect,
        popup,
        toolTip
    },
    computed:{
        ...mapGetters({ userInfo: 'userInfo' }),
    },
    methods:{
          filterOpenForFindings() {
			this.showFiltersForFindings = !this.showFiltersForFindings;
			if (this.showFiltersForFindings == true) {
                this.dummyList = this.tableRow;
            } else {
                this.tableRow = this.dummyList;
            }
			emitter.emit('visible', this.showFiltersForFindings);
		},
        filterOpenForAuditInfo() {
			this.showFiltersForAuditInfo = !this.showFiltersForAuditInfo;
			emitter.emit('visible', this.showFiltersForAuditInfo);
		},
        taskStatusColor(colorCode: any){
            if(colorCode == 10401){
                return ['w-8 h-4 bg-disabledgrey rounded-sm']
            } else if (colorCode == 10415) {
                return ['w-8 h-4 bg-yellow rounded-sm']
            } else if (colorCode == 10414) {
                return ['w-8 h-4 bg-electricGreen rounded-sm']
            } else if (colorCode == 10416) {
                return ['w-8 h-4 bg-primary rounded-sm']
            } else if (colorCode == 10403) {
                return ['w-8 h-4 bg-lightgreen rounded-sm']
            }else if (colorCode == 10417) {
                return ['w-8 h-4 bg-darkred rounded-sm']
            }
        },
        textColor(colorCode: any){
            if(colorCode == 10401){
                return ['font-semibold text-xs text-disabledgrey']
            } else if (colorCode == 10415) {
                return ['font-semibold text-xs text-yellow']
            } else if (colorCode == 10414) {
                return ['font-semibold text-xs text-electricGreen']
            } else if (colorCode == 10416) {
                return ['font-semibold text-xs text-primary']
            } else if (colorCode == 10403) {
                return ['font-semibold text-xs text-lightgreen']
            } else if (colorCode == 10417) {
                return ['font-semibold text-xs text-darkred']
            } else if (colorCode == 10418) {
                return ['font-semibold text-xs']
            }
        },
        getColumnHeadWidth(column: any) {
            if (column == 'tasks') {
                return 'w-28';
            } else {
                return '';
            }
        },
        changeDateFormat(date: any) {
			if (date.includes('-')) {
				let dateParts = date.split('-');
				if (dateParts.length === 6) {
				let startDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				let endDate = `${dateParts[5]}/${dateParts[4]}/${dateParts[3]}`;
				return `${startDate} - ${endDate}`;
				} else if (dateParts.length === 3) {
				return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				}
			}
			return date.split('-').reverse().join('/');
		},
         async toggleTabs(obj: any) {
            this.dataLoading = true;
			this.selectedTabIndex = obj.id;
			this.selectedTabObj = obj;
            this.showFiltersForFindings = false;
            this.showFiltersForAuditInfo = false;
            this.dataLoading = false;
            sessionStorage.setItem('selectedTab', obj.id);
		},
        getFindingCategory(category: any){
            let categoryObj = this.findingCategoryData.find((obj: any) => {
                return obj.id == category;
            });
            if (categoryObj == undefined) {
                return "";
            } else {
                return categoryObj.value;
            }
        },
        async getAllTeams() {
            this.teamObject = []
            let users:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                users = res.data;
                this.teamsData = res.data;
            });
            for (var i = 0; i < users.length; i++) {
                this.teamObject.push({ label: users[i].teamName, value: users[i]._id });
            }
            // this.deptOptions = this.departmentsList.map((item: any) => {
            //     return { value: item._id, label: item.teamName };
            // });
        },
        onclickTerminate(){
            this.showTerminatePopup = true
            // /api/org/compliance/:complianceId/terminate
        },
        showEditPopUp(data: any){
            this.showFindingsPopUp = true;
            this.ActionObj = {...data};
        },
        onCancelTerminate(){
            this.showTerminatePopup = false;
            this.terminateComments = '';
            this.retainFindings = false;
        },
        async TerminatePresentCompliance(){
            let payload:any = {
                "updatedAt": new Date(),
                "updatedBy": this.userInfo._id,
                "statusCode":10418,
                "comments":this.terminateComments,
                "retainFindings": this.retainFindings
            }
            await this.$http.post(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/${this.$route.params.complianceId}/terminate`,payload).then(async (res: any) => {
                console.log('TERMAINATED');
                this.$router.push({name:'compliance'});
			});
        },
        // async closeAudit(){
        //     if(this.tasksOpen == true && this.presentComplianceObject.statusCode == 10402){
        //         toast.error(`Tasks in Pending`, {
        //             timeout: 1000,
        //             closeOnClick: true,
        //             closeButton: 'button',
        //             icon: true
        //         });
        //     }else if(this.tasksOpen == true && this.presentComplianceObject.statusCode == 10401){
        //         toast.error(`Audit Not Started Yet`, {
        //             timeout: 1000,
        //             closeOnClick: true,
        //             closeButton: 'button',
        //             icon: true
        //         });
        //     }else{
        //         console.log('YOU CAN CLOSE AUDIT')
        //     }
        // },
        async getPresentComplianceObject() {
			await this.$http
				.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/${this.$route.params.complianceId}/get`)
				.then((result: { data: any }) => {
					this.presentComplianceObject = result.data;
                     
				})
                
				.catch((error: any) => {
					toast.error(` ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
               let newObj = this.optionsUserArray.find((obj:any)=>{
                        return obj.value == this.presentComplianceObject.teamLeader
                })
                 this.teamLeaderLabel = newObj != undefined ? newObj.label : 'N/A'
                console.log('JJJJJJJJJJJJ', this.teamLeaderLabel);
                
                this.startDate = this.changeDateFormat(this.presentComplianceObject.startDate)
                this.endDate = this.changeDateFormat(this.presentComplianceObject.endDate)
                this.auditPeriod = `${this.changeDateFormat(this.presentComplianceObject.period.fromDate)} - ${this.changeDateFormat(this.presentComplianceObject.period.toDate)}`

                this.presentComplianceObject.entities.forEach((entity: any) => {
                    if (entity.teams && Array.isArray(entity.teams)) {
                        entity.teams.forEach((team: any) => {
                            let teamLeaderObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == team.teamOwner});
                            team.teamOwner = teamLeaderObj!=undefined ? teamLeaderObj.label : '';
                            let auditorObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == team.reviewer});
                            let teamObj:any=team.team;
                            let nameOfTeam: any = this.teamObject.find((obj: any) => {
                                return obj.value == team.team;
                            })
                            let nameOfEntity = this.locationObject.find((obj: any) =>  {
                                return obj.value == team.entity;
                            })
                            team.reviewer = auditorObj!=undefined ? auditorObj.label : '';
                            // let obj = {
                            //     'Team':nameOfTeam,
                            //      'Entity': nameOfEntity,
                            //     'Team Owner': team.teamOwner,
                            //     'Reviewer': team.reviewer
                            // }
                            let obj = {
                                'Team':nameOfTeam != undefined ? nameOfTeam.label : 'N/A',
                                'Entity': nameOfEntity != undefined ? nameOfEntity.label : 'N/A',
                                'Team Owner': team.teamOwner ? team.teamOwner : 'N/A',
                                'Reviewer': team.reviewer ? team.reviewer : 'N/A'
                            }
                            this.teamsArray.push(obj);
                        });
                    }
                });
                
                let teamLeaderObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == this.presentComplianceObject.teamLeader});
                this.presentComplianceObject.teamLeader = teamLeaderObj;
		},
        viewTasks(mainIndex:any){
            let findStatusCode = this.taskStatusCodes[mainIndex]
            if(this.presentComplianceObject.statusCode != 10401){
                this.$router.push({ name: 'start-Compliance', params: { complianceId: this.$route.params.complianceId },query:{tasks:findStatusCode.id} });
            }else{
                toast.error(`Please Start Compliance`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        startPresentCompliance(tableRow:any){
        //     if(!tableRow || tableRow.length === 0){
        //       toast.error(`Cannot Start Compliance as there are no Controls`, {
        //             timeout: 1000,
        //             closeOnClick: true,
        //             closeButton: 'button',
        //             icon: true
        //         });
        //    }else{
            this.$router.push({ name: 'start-Compliance', params: { complianceId: this.$route.params.complianceId  } });

        },
        clickOnPieLabel(event:any, chartContext:any, config:any){
            // alert (chartContext.w.globals.labels[config.dataPointIndex] )
            this.viewTasks(config.dataPointIndex)
        },
         async getTasksCountData() {
            this.series = []
			await this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/${this.$route.params.complianceId}/allstatus`).then(async (result: any) => {
				// this.viewComplianceObj = result.data;

                let obj:any =[
                    {taskStatus:10401, tasks: result.data[0].tasks},
                    {taskStatus:10415, tasks: result.data[2].tasks},
                    {taskStatus:10414, tasks: result.data[1].tasks},
                    {taskStatus:10416, tasks: result.data[4].tasks},
                    {taskStatus:10403, tasks: result.data[5].tasks},
                    {taskStatus:10417, tasks: result.data[3].tasks},
                    {taskStatus:10418, tasks: result.data[6].tasks}
                ]
                this.viewComplianceObj = obj;
                this.viewComplianceObj.map((obj:any)=>{this.series.push(obj.tasks)})

			});
		},
        async getallUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.orgUsersList = res.data;
				for (var i = 0; i < this.orgUsersList.length; i++) {
					// if(this.orgUsersList[i].isActive == true){
						this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
					// }
				}
			});
		},
         async getallEntities() {
            // this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
                this.allEntities = res.data
            });
            for (var i = 0; i < this.allEntities.length; i++) {
                this.locationObject.push({ label: this.allEntities[i].entityName, value: this.allEntities[i]._id });
            }
        },
        async getComplianceTasks(cId:any) {
			this.tasksForCompliance = [];
			await this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/${cId}/tasks/get`).then(async (result: any) => {
				this.tasksForCompliance = result.data;
			});
		},
        getLocationName(locationId: any) {
            let user = this.locationObject.find((obj: any) => {
                return obj.value == locationId;
            });
            if (user == undefined) {
                let empty = 'N/A';
                return empty;
            } else {
                return user.label;
            }
        }, 
        // /api/org/compliance/:complianceId/findings/getall
        async getSingleComplianceFindings(cId:any) {
			this.findingsForCompliance = [];
			await this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/${this.$route.params.complianceId}/findings/getall`).then(async (result: any) => {
				this.findingsForCompliance = result.data;
			});
		},
        globalLoction(){
            let entityObj = this.allEntities.find((entity: any)=>{return entity.entityType == 1})
            return entityObj ? entityObj.entityName : 'N/A';
        },
        async getDetailsForTable(){
            this.tableRow = [];
            this.tasksForCompliance.map((taskObj:any)=>{
                if(taskObj.findings !== undefined){

                    let presentLocation: any;
                    let presentteamObject: any = this.teamsData.find((obj: any) => obj._id == taskObj.departments);
                    // console.log("ObjObjObjObjObjObjObjObj",this.teamsData)
                    let globalId = this.globalLoction()
                    // console.log("globalId", globalId)
                    presentLocation = presentteamObject != undefined && presentteamObject.type == 20301 ? globalId : (taskObj.location ? this.getLocationName(taskObj.location[0].entity) : 'N/A');
                    this.findingsForCompliance.forEach((obj: any) => {
                        const category = this.findingCategoryData.find((id: any) => obj.findingCategory == id.id)?.value;
                        // console.log("findingsForCompliance",this.findingsForCompliance)
                        // console.log("objobjobjobj1111111",obj)
                         const finalObj = {
                            _id: obj._id ,
                            Id: obj.findingId != undefined ? obj.findingId : 'N/A',
                            finding: obj.finding != undefined ? obj.finding : 'N/A',
                            entityName: presentLocation != undefined ? presentLocation : 'N/A',
                            Team: obj.department != undefined ? obj.department : 'N/A',
                            'CCID': taskObj.clientUniqueId != undefined ? taskObj.clientUniqueId : 'N/A',
                            category,
                        };
                        this.teamsData.forEach((data1:any)=>{
                            
                                if(data1._id == finalObj.Team){
                                    finalObj.Team = data1.teamName;
                                }
                        
                        })
                        this.tableRow.push(finalObj);
                    });

                    // let presentLocation:any
                
                    // let presentteamObject:any = this.teamsData.find((obj: any) => {
                    //     return obj._id == taskObj.departments;
                    // });
                    // taskObj.teaName = presentteamObject.teamName
                    // console.log("presentteamObject", presentteamObject.teamName)
                    // if (presentteamObject.type == 20301){
                    //     presentLocation = 'Global';
                    // }else{
                    //     if(taskObj.location == undefined || taskObj.location==null){
                    //         presentLocation = '-'
                    //     }else{
                    //         presentLocation = this.getLocationName(taskObj.location[0].entity)
                    //     }
                    // }
                    // this.findingsForCompliance.map((obj:any)=>{
                    //     let finalObj = {
                    //         Id: obj.findingId,
                    //         finding: obj.comments,
                    //         'KPI Id': taskObj.kpis.kpi_id,
                    //         entity : taskObj.location[0].entity,
                    //         team: taskObj.teaName,
                    //         'control Number': taskObj.controlNumber,
                    //         category: this.findingCategoryData.find((id: any)=> {return obj.findingCategory== id.id}).value
                    //     }
                    //     console.log("finalObjfinalObj", finalObj)
                    //     this.tableRow.push(finalObj)
                    // }) 
                }
            })
        },
        onCancelButtonAddPopUp() {
			this.showFindingsPopUp = false;
		}
    },
     beforeUnmount() {
		 sessionStorage.removeItem('selectedTab')
    },
    async created(){
        this.dataLoading = true;
        emitter.off('clickedOnDropDownAction')
        await this.getallUsers();
        await this.getAllTeams();
        await this.getallEntities();
        await this.getTasksCountData();
        await this.getPresentComplianceObject();
        await this.getComplianceTasks(this.$route.params.complianceId);
        await this.getSingleComplianceFindings();
        await this.getDetailsForTable();
        
        let headersData = ['Id','finding','CCID','Team','entityName']

        this.mainHeaders = headersData;
        if(this.tasksForCompliance.length > 0){
            let result:any = this.tasksForCompliance.every((obj:any)=>{return obj.statusCode==10403 || obj.statusCode == 10417});
            result == true ? this.tasksOpen = false : this.tasksOpen = true;
        }
        this.routesArray = [
            { name: 'Compliance', routeName: "compliance" },
            { name: this.presentComplianceObject.complianceId, routeName: "viewCompliance" }
        ];
        this.navIndexNums = { index: 1, name: 'Task Analytics' };

         const savedTabKey = sessionStorage.getItem('selectedTab');
            if (savedTabKey) {
            const savedTab = this.tabHead.find((tab: any) => tab.id == savedTabKey);
            if (savedTab) {
                    this.toggleTabs(savedTab);
            }
            else{
                this.selectedTabObj = { displayName: 'Task Analytics', id: 1 };
                this.selectedTabIndex = 1;
            }
            }
            else{
                this.selectedTabObj = { displayName: 'Task Analytics', id: 1 };
                this.selectedTabIndex = 1;
            }
            this.dataLoading = false;
        
    },
    async mounted(){
        emitter.off('clickedOnDropDownAction')
		emitter.on('clickedOnDropDownAction',async (actionObj:any)=>{
            this.ActionObj.frameworks = actionObj.frameworks;
            this.ActionObj.findingCategory = actionObj.findingCategory;
            this.ActionObj.findingComment = actionObj.findingComment;
            this.ActionObj.findingEvidence = actionObj.findingEvidence;
            this.ActionObj.complianceResult = actionObj.complianceResult;
            if(actionObj.frameWorkControl != undefined){
                this.ActionObj.frameWorkControl = actionObj.frameWorkControl
            }else{
                this.ActionObj.frameWorkControl = ''
            }
            this.showFindingsPopUp = true;
		})
        
        emitter.off('findingsPopup')
        emitter.on("findingsPopup", (isData: any) => {
            let comingId = isData.entityData._id;
            let showObj = this.findingsForCompliance.find((obj: any) => {
                return obj._id === comingId;
            });
            if(this.selectedTabObj.id == 4){
                this.showEditPopUp(showObj);
            }
        });   
        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {                        
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        }); 
    }
})
</script>






