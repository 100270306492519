<template>
  <div class="pt-3 pl-5 pr-5">
    <div class="row w-full justify-between flex" style="align-items: center">
      <h1 class="title">Objective</h1>
      <div class="flex items-center">
        <button
          class="btnprimary ml-2 flex items-center"
          @click="addBtnObjective()"
        >
          Add
        </button>
        <button
          class="ml-3 flex"
          @click="filterOpen"
          :class="columnObject ? 'filterButtondisabled pointer-events-none' : 'filterButton'"
        >
          Filters
          <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
        </button>
      </div>
    </div>
    <tableData
      :tableRowData="tableRow"
      :headersData="headers"
      :loadData="dataLoading"
    ></tableData>
    <div v-if="toolTipActive">
			<tool-tip :tipText="toolTipText"></tool-tip>
		</div>

    <popup v-if="showPopup" :popUp="showPopup">
      <template v-slot:header>
        <div
          class="flex items-center justify-between popupheadingcontainer rounded"
        >
          <h1 v-if="createObjective._id">Update Objective</h1>
          <h1 v-else>Create Objective</h1>
          <button
            class="float-right text-3xl"
            @click="
              onCancelbtn('cross')
            "
          >
            <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
          </button>
        </div>
      </template>
      <fieldset>
        <div>
          <div>
            <div class="statuscontainer popupbodycontainer">
              <div class="formcontainer flex flex-col">
                <div class="row flex flex-row w-full upperspacing">
                  <div class="w-full">
                    <label class="controllabel">Objective</label>
                    <textarea
                      type="text-area"
                      class="inputboxstyling mt-2"
                      v-model.trim="createObjective.objective"
                      placeholder="Objective"
                      :class="{
                        'is-invalid': v$.createObjective.objective.$error,
                      }"
                    > </textarea>
                    <div
                      v-if="v$.createObjective.objective.$error"
                      class="text-red-500"
                    >
                      <div
                        class="error-text"
                        v-if="v$.createObjective.objective.required.$invalid"
                      >
                        Required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row flex flex-row w-full upperspacing">
                  <div class="w-full">
                    <label class="controllabel">Department</label>
                    <!-- @select="selectedDepartment" -->
                    <div v-if='createObjective._id' disabled class="mt-2 text-sm inputboxstyling">{{createObjective.department?.label || 'N/A'}}</div>
                    <multiselect v-else
                        v-model="createObjective.department"
                        :options="allDeptOptions"
                        :showNoOptions="false"
                        @search-change="asyncFindDepartment"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        open-direction="bottom"
                        label="label"
                        track-by="value"  
                        :searchable="true"
                        :hideArrow="false"
                        placeholder="Department"
                        class="custom-multiselect userSelect mt-2"
                        :class="{
                        'is-invalid borderRed': v$.createObjective.department.$error
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="v$.createObjective.department.$error"
                      class="text-red-500"
                    >
                      <div
                        class="error-text"
                        v-if="v$.createObjective.department.required.$invalid"
                      >
                        Required
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="row flex flex-row w-full upperspacing">
                  <div class="w-full">
                    <label class="controllabel">Entity</label>
                      <div v-if="isGlobal " class="mt-2 text-sm inputboxstyling">
                        {{ createObjective.entity }}
                      </div>
                      <div v-else-if="createObjective._id && !isGlobal" class="mt-2 text-sm inputboxstyling">
                        {{ createObjective.entity?.label || 'N/A' }}
                      </div>
                        <multiselect v-else
                          v-model="createObjective.entity"
                          :options="allEntityOptions"
                          :showNoOptions="false"
                          :disabled="departmentSelected"
                          @search-change="asyncFind2"
                          :multiple="false"
                          :close-on-select="true"
                          :clear-on-select="true"
                          :hide-selected="true"
                          open-direction="bottom"
                          label="label"
                          track-by="value"  
                          :searchable="true"
                          :hideArrow="false"
                          placeholder="Entity"
                          class="custom-multiselect userSelect mt-2"
                          :class="{
                          'is-invalid borderRed': v$.createObjective.entity.$error
                        }"
                      >
                      </multiselect>
                      <div v-if="v$.createObjective.entity.$error" class="text-red-500">
											<div class="error-text" v-if="v$.createObjective.entity.required.$invalid">Required</div>
										</div>
                  </div>
                </div> -->
                <div class="formcontainer flex flex-col upperspacing">
                  <div class="row flex flex-row w-full">
                    <div class="w-6/12">
                      <label class="controllabel">Responsibility</label>
                      <multiselect
                        v-model="createObjective.user"
                        :options="userOptionsArr"
                        @search-change="asyncFind"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        label="label"
                        open-direction="bottom"
                        :hide-selected="true"
                        track-by="value"
                        :searchable="true"
                        :hideArrow="false"
                        placeholder="Responsibility"
                        deselectLabel=""
                        :showNoOptions="false"
                        selectLabel=""
                        class="custom-multiselect userSelect mt-2"
                        :class="{
                          borderRed: v$.createObjective.user.$error,
                        }"
                      >
                      <template v-slot:noResult>
                        <span>No Members Found</span>
                      </template>
                      </multiselect>
                      <div
                        v-if="v$.createObjective.user.$error"
                        class="text-red-500"
                      >
                        <div
                          class="error-text"
                          v-if="v$.createObjective.user.required.$invalid"
                        >
                          Required
                        </div>
                      </div>
                    </div>
                    <div class="w-3/6 leftspacing">
                      <label class="controllabel">Date Of Target</label>
                      <input
                        type="date"
                        class="mt-2"
                        @focus="openCalendar" 
                        onkeydown = "return false"
                        v-model="createObjective.dateOfCompletion"
                        :min="getTodayDate()"
                        :class="{
                          'is-invalid':
                            v$.createObjective.dateOfCompletion.$error,'inputboxstylingForSelectAtPopup': createObjective.dateOfCompletion == null && createObjective.dateOfCompletion == undefined,'stylingForInputboxstylingForSelectAtPopup': createObjective.dateOfCompletion != null && createObjective.dateOfCompletion != undefined 
                        }"
                      />
                      <div
                        v-if="v$.createObjective.dateOfCompletion.$error"
                        class="text-red-500"
                      >
                        <div
                          class="error-text"
                          v-if="v$.createObjective.dateOfCompletion.required.$invalid"
                        >
                          Required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row flex flex-row w-full upperspacing">
                  <div class="w-full">
                    <label class="controllabel">Description</label>
                    <textarea
                    rows="3"
                      type="text-area"
                      class="inputboxstyling mt-2"
                      v-model.trim="createObjective.description"
                      placeholder="Description"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-if="createObjective._id" class="buttonposition flex justify-center items-center mb-4 mx-0">
							<button class="mr-3" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn(createObjective)">Cancel</button>
							<button :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdate()">Update</button>
						</div> -->
          <div
            class="buttonposition flex justify-center items-center mb-4 mx-0"
          >
            <button
              :class="
                disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'
              "
              class="mr-1.5 btnprimary"
              @click="onCancelbtn('cancel')"
            >
              Cancel
            </button>
            <button
              v-if="createObjective._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" :disabled="clickOnButton" @click="addOrUpdate(createObjective)">
              Update
            </button>
            <button
              v-else
              :class="
                disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'
              "
              :disabled="clickOnButton"
              class="btnprimary"
              @click="saveNewObjective()"
            >
              Save
            </button>
          </div>
        </div>
      </fieldset>
    </popup>
    <!-- <loader v-if="showLoader" /> -->
  </div>
</template>
<style scoped>
.borderRed {
  border: 1px solid red !important;
}
</style>
<script lang="ts">
import { emitter, toast } from "@/main";
import { defineComponent } from "vue";
import { required,requiredIf } from "@vuelidate/validators";
// import useVuelidate from '@vuelidate/core';
import { useVuelidate } from "@vuelidate/core";
import tableData from "@/components/tableData.vue";
import popup from "@/components/popup.vue";
// import Multiselect from '@vueform/multiselect';
import multiselect from "vue-multiselect";
// import loader from "@/components/loader.vue";
import _ from "lodash";
import toolTip from "@/components/toolTip.vue";

export default defineComponent({
  data(): any {
    return {
      headers: [
        "_id",
        "objective",
        "department",
        "Entity",
        "responsibility",
        "Date of Target",
        'Total Number of Controls',
        "actionButtonAtObjective"
      ],

      v$: useVuelidate(),
      userOptionsArr: [],
      rowObj: [],
      objectiveList: [],
      controlsList: [],
      tableRow: [],
      orgUsersList: [],
      optionsUserArray: [],
      controlsArray: [],
      controlsLength: [],
      finalTeamOwner: "",
      showFilters: false,
      showLoader: false,
      dummyList: [],
      showPopup: false,
      allDeptOptions: [],
      columnObject: false,
      actionBtn: true,
      dummyObj: [],
      controlAllList: [],
      editPop: false,
      createObjective: {
        objective: "",
        department: "",
        user: "",
        dateOfCompletion: "",
        description: "",
      },
      objectiveClassTypeArr: [
        { label: "Internal", id: 1 },
        { label: "External", id: 2 },
      ],
      optionsValue: [
        {
          id: 1,
          value: "Map",
          route: "/objectiveControls",
          presentRoute: "/org/objective",
        },
      ],
      departmentsList: [],
      uniqueDeptOptions: [],
      objectiveUserTypeArr: [],
      objectiveDepartmentTypeArr: [],
      clickOnButton:false,
      toolTipActive:false,
      toolTipText:'',
      entitiesArray:[],
      allEntityOptions:[],
      isGlobal:false,
      departmentSelected:true,
      dataLoading:true,
    };
  },
  validations: {
    createObjective: {
      objective: { required },
      department: { required },
      // entity:{
      //     required
      //   },
      user: { required },
      dateOfCompletion: { required },
    },
  },
  components: {
    tableData,
    popup,
    multiselect,
    toolTip
    // ToolTipt,
    // loader,
  },
  async mounted() {
    this.dataLoading = true;
    await this.fetchDepartmentsList();
    await this.getallEntities();
    await this.getAllUsers();
    await this.fetchControlsInfo();
    await this.fetchobjectiveList();
    this.dataLoading = false;
    
      	emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {  	
          		                      
            this.toolTipActive = true;
            this.toolTipText = data.tipText
            
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });

    emitter.off("showDataObjective")
    emitter.on("showDataObjective", (isData: any) => {
      let comingId = isData.entityData._id;
      let showObj = this.objectiveList.find((obj: any) => {
        return obj._id === comingId;
      });
      this.showEditPopUp(showObj);
    });
  },
  computed: {
    disableStatus: function (): any {
      let output;
      if (this.editPop) {
        let objCopy = Object.assign({}, this.createObjective);
        if (JSON.stringify(objCopy) == JSON.stringify(this.dummyObj)) {
          output = false;
        } else {
          output = true;
        }
      } else {
        let val: any = [];
        Object.values(this.createObjective).forEach((value: any) => {
          val.push(value);
        });
        for (let n of val) {
          if (n != "") {
            output = true;
          }
        }
      }
      return output;
    },
  },
  methods: {
     asyncFind(query:any){
			if(query == ''){
				this.userOptionsArr = []
			}else if(query.length > 1){
				this.userOptionsArr = this.objectiveUserTypeArr.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
    asyncFindDepartment(query:any){
        if(query == ''){
            this.allDeptOptions = []
        }else if(query.length > 1){
            this.allDeptOptions = this.uniqueDeptOptions.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
        }
    },  
    asyncFind2(query:any){
      if(query == ''){
          this.allEntityOptions = []
      }else if(query.length > 1){
          this.allEntityOptions = this.entitiesOptions.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
      }
    },
    // selectedDepartment(){
      
    //   this.createObjective.entity = ''
    //   this.departmentSelected = false
    //   this.entitiesOptions = []
      
    //   if(this.createObjective != undefined && this.createObjective.department != undefined){
    //     if(this.createObjective.department.type == 20301){
    //       this.isGlobal = true;
    //       this.createObjective.entity = 'Global'
    //     } else{
    //       this.isGlobal = false;
    //       let selectedTeam: any = this.createObjective.department.value
    //       let selectedTeamObjs: any = this.departmentsList.filter((obj: any)=>{
    //         return obj._id == selectedTeam
    //       })
    //       selectedTeamObjs.map((obj:any)=>{
    //         let myEntObj:any = this.entitiesArray.find((item:any)=>{return item.value == obj.entity;})
    //         if(myEntObj!= undefined){
    //           this.entitiesOptions.push(myEntObj)
    //         }
    //       })
    //     }
    //   }
    // },
    getTodayDate() {
      const today = new Date();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const formattedMonth = month < 10 ? "0" + month : "" + month;
      const formattedDay = day < 10 ? "0" + day : "" + day;
      return `${today.getFullYear()}-${formattedMonth}-${formattedDay}`;
    },
    filterOpen() {
      if (this.columnObject == false) {
        this.showFilters = !this.showFilters;
        emitter.emit("visible", this.showFilters);
      }
    },
    openCalendar(event:any) {
			event.target.showPicker();
		},
    showEditPopUp(obj: any) {
      this.editPop = true;
          // if(obj.entitytypecodeid==20303){
          //   this.entitiesArray.find((data:any)=>{
          //     if(obj.entity==data.value){
          //         obj.entity= {value:data.value,label:data.label}                          
          //     }
          //   }) 
          // }   
          // else if(obj.entitytypecodeid==20301){
          //   this.isGlobal=true;
          //   obj.entity='Global'
          // }       
        
      this.createObjective = { ...obj };
      this.dummyObj = { ...this.createObjective };
      this.showPopup = true;
    },
    onCancelbtn(state: any) {
      this.v$.$reset();
      if (state == 'cross') {
        Object.keys(this.createObjective).forEach((prop) => {
          Array.isArray(this.createObjective[prop])
            ? (this.createObjective[prop] = [])
            : (this.createObjective[prop] = "");
        });
        Object.keys(this.dummyObj).forEach((prop) => {
          Array.isArray(this.dummyObj[prop])
            ? (this.dummyObj[prop] = [])
            : (this.dummyObj[prop] = "");
        });
				this.showPopup = false;
      } else if(state == 'cancel') {
        this.createObjective = { ...this.dummyObj };
      }
      this.isGlobal = false;
    },
    changeDateFormat(date: any) {
			if (date.includes('-')) {
				let dateParts = date.split('-');
				if (dateParts.length === 6) {
				let startDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				let endDate = `${dateParts[5]}/${dateParts[4]}/${dateParts[3]}`;
				return `${startDate} - ${endDate}`;
				} else if (dateParts.length === 3) {
				return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				}
			}
			return date.split('-').reverse().join('/');
		},
    addBtnObjective() {
      // Object.keys(this.createObjective).forEach((prop) => {
      //   Array.isArray(this.createObjective[prop])
      //     ? (this.createObjective[prop] = [])
      //     : (this.createObjective[prop] = "");
      // });
      this.createObjective = {}
      this.showPopup = true;
      this.editPop = false;
      this.v$.$reset();
    },
    async addOrUpdate(createObjective: any) {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        console.log('createObjective',createObjective)
        this.clickOnButton = true;
        try{
         
        // if(createObjective.department.type==20301){
        //   createObjective.entitytypecodeid = 20301;
        //   createObjective.entity ='global';
        // }
        // else if(createObjective.department.type==20303){
        //   createObjective.entitytypecodeid = 20303;
        //   createObjective.entity = createObjective.entity.value;
        // }

          
        let dummyUpdateObj:any={...this.createObjective}   
            
        dummyUpdateObj.user = dummyUpdateObj.user.value
        dummyUpdateObj.department = dummyUpdateObj.department.value

        // dummyUpdateObj.entitytypecodeid = dummyUpdateObj.entity=='Global' ? 20301 : 20303
        // dummyUpdateObj.entity = dummyUpdateObj.entity=='Global' ? 'global':dummyUpdateObj.entity.value
        
        // if (createObjective._id) {
          this.showLoader = true;
          await this.$http.post(
            `${process.env.VUE_APP_CONTROLS_API_URL}/api/org/objective/${createObjective._id}/update`,
            _.omit(dummyUpdateObj, ["_id"])
          )
          .then(async () => {
            this.showLoader = false;
            this.showPopup = false;
            toast.info(`Updated Successfully`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
            await this.fetchobjectiveList();
          });
        }catch{
          toast.error(`cannot updated`, {
          timeout: 1000,
          closeOnClick: true,
          closeButton: 'button',
          icon: true
          });
        } finally {
          this.clickOnButton = false;
        }
      } 
      // else {
      //   this.showLoader = false;
      //   toast.error(`error`, {
      //     timeout: 1000,
      //     closeOnClick: true,
      //     closeButton: "button",
      //     icon: true,
      //   });
      // }
      // this.showPopup = false;
      this.showLoader = false;
    },
    async fetchDepartmentsList() {
      try {
        this.uniqueDeptOptions = [];
        this.showLoader = true;
        await this.$http
          .get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`)
          .then((res: { data: any }) => {
            this.departmentsList = res.data;
            for (var i = 0; i < res.data.length; i++) {            
              let a = {value:res.data[i]._id, label:res.data[i].teamName,type:res.data[i].type}
              this.objectiveDepartmentTypeArr.push(a);
              let findObj:any = this.uniqueDeptOptions.find((obj:any)=>{return obj.value == res.data[i]._id});
              if(findObj == undefined){
                  this.uniqueDeptOptions.push(a)
              }
            }
          });
      } catch (e) {
        toast.error("error", {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
      this.showLoader = false;
    },
     async getallEntities() {
      this.entitiesOptions = [];
      let result: any;
      await this.$http
        .get(
          `${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`
        )
        .then((res: any) => {
          result = res.data;
          this.allEntities = res.data;
        });
      for (var i = 0; i < result.length; i++) {
        this.entitiesArray.push({
          label: result[i].entityName,
          value: result[i]._id,
        });
      }
    },
    async getAllUsers() {
      this.showLoader = true;
      await this.$http
        .get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`)
        .then((res: any) => {
          this.orgUsersList = res.data;
        });
      // this.orgUsersList = this.orgUsersList.filter((user: any) => {
      //   return user.isActive == true;
      // });
      for (var i = 0; i < this.orgUsersList.length; i++) {
        this.objectiveUserTypeArr.push({label: this.orgUsersList[i].name,
          value: this.orgUsersList[i]._id});
      }
    },
    saveNewObjective() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.clickOnButton = true;
        try{
          
          // if(this.createObjective.department.type==20301){
          //   this.createObjective.entitytypecodeid = 20301;
          //   this.createObjective.entity='global'
          // }
          // else if(this.createObjective.department.type==20303){
          //   this.createObjective.entitytypecodeid = 20303;
          //   this.createObjective.entity = this.createObjective.entity.value
          // }
        this.createObjective.user = this.createObjective.user.value
        this.createObjective.department = this.createObjective.department.value
        const addObject = {...this.createObjective};

        // addObject.user = addObject.user.value
        // addObject.department = addObject.department.value
        // addObject.entitytypecodeid = addObject.entity=='Global' ? 20301 : 20303
        // addObject.entity = addObject.entity=='Global' ? 'global':addObject.entity.value
        
        let dummyObj:any=[addObject];
        
        this.showLoader = true;
        this.$http
          .post(
            `${process.env.VUE_APP_CONTROLS_API_URL}/api/org/objective/create`,
            dummyObj
          )
          .then(async () => {
            await this.fetchobjectiveList();
            this.showPopup = false;
            toast.info(`Created`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
          });
        }catch{
            toast.error(`cannot updated`, {
            timeout: 1000,
            closeOnClick: true,
            closeButton: 'button',
            icon: true
          });
        } finally {
          this.clickOnButton = false;
        }
      }
      this.showLoader = false;
    },
    async fetchControlsInfo() {
			try {
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active`).then((result: { data: any }) => {
					this.controlAllList = result.data;
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
    async fetchobjectiveList() {
      this.objectiveList = [];
      this.tableRow = [];
      this.dataLoading = true;
      try {
        this.showLoader = true;
        await this.$http
          .get(
            `${process.env.VUE_APP_CONTROLS_API_URL}/api/org/objectives/getall`
          )
          .then((result: { data: any }) => {
            result.data.forEach((objective: any)=>{
              objective.user = this.objectiveUserTypeArr.find((user: any) => {return user.value === objective.user})
              objective.department = this.objectiveDepartmentTypeArr.find((user: any) => {return user.value === objective.department})
              this.objectiveList.push(objective);
            });
            console.log("this.objectiveList", this.objectiveList)
            // this.rowObj = [...this.objectiveList];
            this.actionBtn = true;
            if (this.objectiveList.length > 0) {
              this.objectiveList.forEach((object: any) => {
                if(object._id != undefined || object._id != '' || object._id != null){
                  let departmentName = object.department && object.department.label ? object.department.label : object.department;
                  let objectiveControlsLength: any = [];
                  if(object.mappedControls && object.mappedControls.length != 0){
                    object.mappedControls.forEach((control: any)=>{
                      let obj = this.controlAllList.find((each: any)=>{return control == each._id})
                      if(obj != undefined){
                        objectiveControlsLength.push(obj)
                      }
                    })
                  }
                  let entityName:any;
                  // if(object.entitytypecodeid==20301){
                  //   entityName = 'Global'
                  // }
                  // else if (object.entitytypecodeid == 20303){
                       let newDept = this.departmentsList.find((obj:any)=>{
                          return obj._id == object.department
                       })
                    // let entity = this.entitiesArray.find((obj: any) => {
                    //     return obj.value == object.entity;
                    //   });
                    if(newDept != undefined){
                       entityName =  newDept.entityType == 20303 ? newDept.entityDisplayName : newDept.displayName
                    }
                  // }
                  
                  console.log("objectiveControlsLength", objectiveControlsLength.length)
                  // object.department = departmentName != undefined ? departmentName.label : ''
                  console.log('dep',departmentName);
                  let dateOfTarget = `${this.changeDateFormat(object.dateOfCompletion)}`
                  let finalObject = {
                    _id: object._id,
                    objective: object.objective != undefined ? object.objective : 'N/A',
                    department: departmentName != undefined ? departmentName : 'N/A',
                    Entity: entityName != undefined ? entityName : 'N/A' ,
                    responsibility: object.user != undefined ? object.user.label : 'N/A',
                    "Date of Target": dateOfTarget != undefined ? dateOfTarget : 'N/A',
                    'Total Number of Controls': objectiveControlsLength ? objectiveControlsLength.length: 0
                  }
                  this.tableRow.push(finalObject);
                  
                }
              });
            } else {
              this.actionBtn = false;
              this.tableRow.push({
                _id: "",
                objective: "",
                department: "",
                responsibility: "",
                "Date of Target": "",
                'Total Number of Controls': ""
              });
            }
            if (this.objectiveList && this.objectiveList.length == 0) {
              this.columnObject = true;
            } else {
              this.columnObject = false;
            }
          });
      } catch (e) {
        toast.error("error", {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
      this.showLoader = false;
      this.dataLoading = false;
    },
  }
});
</script>

